import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import Text from '../../../components/style/text.component';

import { getNumberParticipants } from '../../../services/guide.service';
import { useLogin } from '../../../utils/auth.utils';
import colors from '../../../themes/colors-v2.theme';
import { Flex } from '../../../components/style/flex.component';
import useLocale from '../../../utils/locale/locale.hook';

enum SubmitStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

interface GuideCardProps {
  guide: any;
}

export default function GuideCard({ guide }: GuideCardProps) {
  const { me } = useLogin();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.IDLE);
  const [participants, setParticipants] = useState({
    participants: 0,
    going: 0,
    interested: 0,
  });
  const locale = useLocale();

  const hasDuration = guide.type === 'event' || guide.type === 'accommodation' || guide.type === 'art_culture';

  useEffect(() => {
    const getNumbers = async () => {
      if (guide?.id) {
        const res = await getNumberParticipants(guide.id);
        if (!res.error) {
          setParticipants(res);
        }
      }
    };
    getNumbers();
  }, [guide]);

  const numberDisplay = (number: number) => {
    return number.toString();
  };

  const myParticipation = guide.participants?.find((participant: any) => participant.user?.id === me.id);
  const myParticipationStatus = myParticipation?.status || null;
  const participantsGoing = guide.participants?.filter((participant: any) => participant.status === 'going');
  const startDate = moment(guide.start_datetime).format('DD/MM/YYYY');
  const endDate = moment(guide.end_datetime).format('DD/MM/YYYY');

  return (
    <CardWrapper isDesktop={isDesktop}>
      <CardPicture alt="" src={guide.picture.path} />
      <CardContent>
        <Text fontSize="18px" bold>
          {guide.title}
        </Text>
        <Text fontSize="12px" style={{ fontWeight: 400, marginTop: 12 }}>
          {guide.address}
        </Text>

        <Flex alignItems="center" style={{ marginTop: 8 }}>
          <img
            src="/assets/icons/sidebar/navbar/calendar-mini.svg"
            alt="calendar"
            style={{ marginRight: 8 }}
            width={12}
            height={12}
          />
          <Text fontSize="10px" color={colors.lightGrey9} style={{ marginRight: 16 }}>
            {hasDuration && startDate !== endDate ? `${startDate} - ${endDate}` : startDate}
          </Text>
          <Text fontSize="12px" style={{ lineHeight: '10px' }}>{`${numberDisplay(participants.going)} ${locale(
            'guide.events.participants'
          )}`}</Text>
        </Flex>
      </CardContent>
    </CardWrapper>
  );
}

const CardWrapper = styled.div<{ isDesktop: boolean }>`
  cursor: pointer;
  box-sizing: border-box;
  min-height: 320px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: transparent;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    min-height: 193px;
    max-height: 193px;
    position: relative;
  }
`;

const CardPicture = styled.img`
  object-fit: cover;
  width: 100%;
  height: 250px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  @media (max-width: 1000px) {
    height: 193px;
    min-height: 193px;
    max-height: 193px;
    border-radius: 4px;
  }
`;

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  color: ${colors.white};
  display: flex;
  flex-direction: column;

  & > p.date {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    color: ${colors.white};
    margin-bottom: 5px;
  }

  & > p.title {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    color: ${colors.white};
    margin-bottom: 12px;
  }

  & > p.infos {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    color: ${colors.white};
    margin-bottom: 5px;
  }

  & > p.participants {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    color: ${colors.lightGrey10};
    margin-bottom: 12px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    height: 193px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & > p.date,
    p.title,
    p.infos,
    p.participants {
      color: ${colors.white};
    }
    & > p.title {
      order: 1;
      font-size: 18px;
      margin-bottom: 5px;
    }
    & > p.date {
      order: 2;
      margin-bottom: 7px;
    }
    & > p.infos {
      display: none;
    }
    & > p.participants {
      order: 3;
      margin-bottom: 0;
    }
  }
`;
