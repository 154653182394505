import moment from 'moment';
import { get } from '../../services/utils.service';
import { store } from '../../store/configure.store';
import LocaleSlice from '../../store/slices/locale.slice';

export const languages = ['en', 'fr', 'es', 'de', 'it', 'pt', 'nl', 'el', 'ca', 'ja', 'pl'];

export const languageMapping = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  de: 'Deutsch',
  it: 'Italiano',
  pt: 'Português',
  nl: 'Nederlands',
  el: 'Ελληνικά',
  ca: 'Català',
  ja: '日本語',
  pl: 'Polski',
};

export const languageMobileMapping = {
  en: 'EN',
  fr: 'FR',
  es: 'ES',
  de: 'DE',
  it: 'IT',
  pt: 'PT',
  nl: 'NL',
  el: 'EL',
  ca: 'CA',
  ja: 'JA',
  pl: 'PL',
};

export const getAvailableLanguage = () => {
  return [...languages];
};

export const getDefaultLanguage = () => {
  const language = navigator.language.split('-')[0];
  if (languages.includes(language)) return language;
  return languages[0];
};

const loadLocale = async (language: any) => {
  const r = await get('/locales/' + language);
  const json = await r.json();
  return json;
};

export const setLocale = async (language: any) => {
  store.dispatch(LocaleSlice.actions.setLanguage(language));
  localStorage.setItem('language', language);
  const keys = await loadLocale(language);
  if (keys) {
    store.dispatch(LocaleSlice.actions.setKeys(keys));
  }
};

export const replace = (str: string, obj: any) => {
  if (str) {
    for (const key of Object.keys(obj)) {
      const regex = '{{' + key + '}}';
      str = str.replace(new RegExp(regex, 'g'), obj[key]);
    }
  }
  return str;
};

export const updateLocaleLanguage = async (language: any) => {
  if (!localStorage.getItem('units')) {
    if (language === 'en') {
      localStorage.setItem('units', 'imperial');
    } else {
      localStorage.setItem('units', 'metric');
    }
  }
  await setLocale(language);
  moment.locale(language);
};
