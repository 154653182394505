/**
 * Fonction permettant d'effectuer un "trim" sur toutes les valeurs d'un objet.
 */
export const trimObject = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      obj[key] = obj[key].trim();
    }
  }
};

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}