import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import UserConversations from './components/user-conversations.component';
import DisplayedConversation from './components/displayed-conversation.component';
import ScreenWrapper from '../../components/style/screen-wrapper.component';

import { getConversationMedias } from '../../services/chat.service';
import NavbarSlice from '../../store/slices/navbar.slice';
import ModalSlice from '../../store/slices/modal.slice';
import { useLogin } from '../../utils/auth.utils';

import { MobileDisplay } from './types/mobile-display.enum';

import SelectPicturesModal from "../../components/miscellaneous/modals/select-picture.modal";

enum ScreenMode {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
}

export default function MessagesScreenV2() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLogin } = useLogin();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const userConversationsRef = useRef(null);
  const history = useHistory();

  const [mode, setMode] = useState(ScreenMode.DESKTOP);
  const [mobileDisplay, setMobileDisplay] = useState(MobileDisplay.CONVERSATIONS);

  const [newConversationUser, setNewConversationUser] = useState(null);
  const [selectedConversation, setSelectedConversation] = useState(null);

  const [conversationIdToSelect, setConversationIdToSelect] = useState<number>(null);
  const [conversationIdToRemove, setConversationIdToRemove] = useState<number>(null);

  const [conversationMedias, setConversationMedias] = useState([]);
  const [hasMoreMedias, setHasMoreMedias] = useState(false);

  const { conversationId: paramConversationId }: any = useParams();

  const [hasNoConversations, setHasNoConversations] = useState<boolean>(false);

  const selectConversationHandler = (conversation: any) => {
    setSelectedConversation(conversation);
    if (mode === ScreenMode.MOBILE) {
      setMobileDisplay(MobileDisplay.MESSAGES);
    }
    history.push(`/messages/${conversation.id}`);
  };

  useEffect(() => {
    if (location.pathname === '/messages') {
      setMobileDisplay(MobileDisplay.CONVERSATIONS);
    } else {
      setMobileDisplay(MobileDisplay.MESSAGES);
    }
  }, [location.pathname]);

  /**
   * useEffect permettant de sélectionner la dernière conversation existante si aucune n'est sélectionnée.
   */
  useEffect(() => {
    const state = location.state as any;
    if (state?.conversationId) {
      setConversationIdToSelect(state?.conversationId);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('/new')) {
      const state = location.state as any;
      if (state?.user) {
        setNewConversationUser(state.user);
        setSelectedConversation(null);
      } else {
        setNewConversationUser({});
        setSelectedConversation(null);
      }
    } else {
      setNewConversationUser(null);
    }
  }, [location]);

  // Récupére les médias (images, ...) d'une conversation lorsque celle-ci est sélectionnée
  useEffect(() => {
    if (selectedConversation) {
      setConversationMedias([]);
      getConversationMedias(selectedConversation.id, 0).then((medias) => {
        if (Array.isArray(medias)) {
          setHasMoreMedias(medias.length >= 10);
          setConversationMedias(medias);
        }
      });
    }
  }, [selectedConversation]); // eslint-disable-line

  useEffect(() => {
    if (isDesktop) {
      setMode(ScreenMode.DESKTOP);
      setMobileDisplay(MobileDisplay.CONVERSATIONS);
    } else {
      setMode(ScreenMode.MOBILE);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (mobileDisplay === MobileDisplay.MESSAGES || mobileDisplay === MobileDisplay.MEDIAS) {
      dispatch(NavbarSlice.actions.setShowMobileNavbar(false));
    } else {
      dispatch(NavbarSlice.actions.setShowMobileNavbar(true));
    }

    return () => {
      dispatch(NavbarSlice.actions.setShowMobileNavbar(true));
    };
  }, [mobileDisplay, dispatch]);

  useEffect(() => {
    if (paramConversationId && !selectedConversation) {
      setConversationIdToSelect(paramConversationId);
    }
  }, [paramConversationId]);

  useEffect(() => {
    if (!isLogin) {
      dispatch(
        ModalSlice.actions.setSignin({
          visible: true,
          canCloseSignin: false,
        })
      );
    }

    return () => {
      dispatch(
        ModalSlice.actions.setSignin({
          visible: false,
          canCloseSignin: true,
        })
      );
    };
  }, [isLogin, dispatch]);

  useEffect(() => {
    if (selectedConversation?.id === conversationIdToSelect) {
      setConversationIdToSelect(null);
    }
  }, [selectedConversation, conversationIdToSelect]);

  const refreshConversationList = () => {
    // Uses useImperativeHandle to allow one component to refresh the conversation
    userConversationsRef?.current?.refreshConversations();
  };
  const { path } = useRouteMatch();

  const modalStore = useSelector((state: any) => state.modalStore);

  return (
    <FlexRowScreenWrapper shouldHavePadding={mobileDisplay === MobileDisplay.CONVERSATIONS} isDesktop={isDesktop}>
      {modalStore.selectPictures && (
                <SelectPicturesModal visible={modalStore.selectPictures.visible} />
      )}
      {mode === ScreenMode.DESKTOP || (mode === ScreenMode.MOBILE && mobileDisplay === MobileDisplay.CONVERSATIONS) ? (
        <UserConversations
          ref={userConversationsRef}
          selectedConversation={selectedConversation}
          selectConversationHandler={selectConversationHandler}
          newConversationUser={newConversationUser}
          hasNoConversations={!isDesktop && hasNoConversations}
          setHasNoConversations={setHasNoConversations}
          options={{
            shouldSelectFirstByDefault: true,
            selectConversationById: conversationIdToSelect,
            removeConversationById: conversationIdToRemove,
          }}
        />
      ) : null}
      {mode === ScreenMode.DESKTOP || (mode === ScreenMode.MOBILE && mobileDisplay === MobileDisplay.MESSAGES) ? (
        <Switch>
          <Route path={`${path}/:conversationId`}>
            <DisplayedConversation
              setMobileDisplay={setMobileDisplay}
              conversationId={selectedConversation?.id}
              newConversationUser={newConversationUser}
              hasNoConversations={hasNoConversations}
              goBackToProfile={() => window.history.back()}
              conversationCreationCallback={(conversation) => {
                setSelectedConversation(conversation);
                setHasNoConversations(false);
              }}
              refreshConversationList={refreshConversationList}
            />
          </Route>
        </Switch>
      ) : null}
    </FlexRowScreenWrapper>
  );
}

const FlexRowScreenWrapper = styled(ScreenWrapper)<{ isDesktop: boolean }>`
  display: flex;
  margin-left: 0;
  flex-direction: row;
  padding: 0px;
  height: 100vh;
`;
