import { useEffect } from 'react';

export const useOnClickOutside = (refs: React.RefObject<any>[], callback: Function) => {
  const handleClickOutside = (event) => {
    const clickedInside = refs.some((ref) => {
      return (ref.current && ref.current.contains(event.target)) || event.target.closest('.ReactModalPortal');
    });
    if (!clickedInside) {
      callback();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
};
