import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Loader } from "@googlemaps/js-api-loader";

import useLocale from "../utils/locale/locale.hook";

import Button from "./forms/button.component";
import Icons from "./style/icons.component";
import GoogleAutocomplete from "../components/forms/google-autocomplete.component";
import TripForm from "../pages/explore/components/trip.form";

import colors from "../themes/colors-v2.theme";
import fonts from "../themes/fonts.theme";

interface MapContainerProps {
  center: { latitude: number; longitude: number };
  zoom: number;
  options?: {
    canSetPosition: boolean;
    canSearchPosition: boolean;
    canChangeMarkerPosition: boolean;
  };
  onChangeCoords?: ({ latitude, longitude }) => void;
  markerFixed?: boolean;
  fixedText?: string;
  onMarkerFixedChange?: string;
  autocomplete: boolean;
  onSetNewPosition?: () => void;
  isTrips?: boolean;
  refresh?: () => void;
  fixedTextTrips?: string;
}

const MapContainer = (props: MapContainerProps) => {
  const {
    options = {
      canSetPosition: true,
      canSearchPosition: true,
      canChangeMarkerPosition: true,
    },
  } = props;
  const locale = useLocale();

  const [map, setMap] = useState(null);
  const [centerCoords, setCenterCoords] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [hasMoved, setHasMoved] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  let marker = null;

  const addMarker = (latitude: any, longitude: any) => {
    if (marker) marker.setMap(null);
    marker = new google.maps.Marker({
      position: {
        lat: latitude,
        lng: longitude,
      },
      icon: "/assets/icons/explore/icons-actions-localisation-orange-shadow.svg",
    });
    marker.setMap(map);
    map.panTo(marker.getPosition());
  };

  const setSearchPosition = (latitude: any, longitude: any) => {
    if (map) {
      var latLng = new google.maps.LatLng(latitude, longitude);
      map.panTo(latLng);
      setHasMoved(true);
    }
  };

  const setNewPosition = () => {
    props.onChangeCoords({
      latitude: centerCoords.lat,
      longitude: centerCoords.lng,
    });
    props.onSetNewPosition();
  };

  const openTripsForm = () => {
    setFormVisible(true);
  };

  const handleSetSearchPosition = (data) => {
    setSearchPosition(data.lat, data.lng);
    setHasSearched(true);
  };

  // initialisation : chargement de Google Maps
  useEffect(() => {
    loader.load().then(() => {
      const res = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          center: {
            lat: props.center.latitude,
            lng: props.center.longitude,
          },
          streetViewControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          zoom: props.zoom,
          mapTypeControl: false,
          styles: mapStyles,
          fullscreenControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: 1.0,
          },
        }
      );
      setMap(res);
    });
  }, []);

  useEffect(() => {
    setCenterCoords({
      lat: props.center.latitude,
      lng: props.center.longitude,
    });
  }, []);

  // logique après initialisation
  useEffect(() => {
    if (!!map) {
      if (!props.markerFixed) {
        addMarker(props.center.latitude, props.center.longitude);
        if (options.canChangeMarkerPosition) {
          map.addListener("click", (mapsMouseEvent: any) => {
            const coords = JSON.parse(JSON.stringify(mapsMouseEvent.latLng));
            addMarker(coords.lat, coords.lng);
            props.onChangeCoords({
              latitude: coords.lat,
              longitude: coords.lng,
            });
            marker.setMap(map);
          });
        }
      }
      if (props.markerFixed) {
        map.addListener("center_changed", () => {
          setHasMoved(true);
          setHasSearched(false);
          const center = map.getCenter();
          const coords = JSON.parse(JSON.stringify(center));
          setCenterCoords(coords);
          props.onChangeCoords({
            latitude: coords.lat,
            longitude: coords.lng,
          });
        });
      }
    }
  }, [map]);

  return (
    <Container>
      <Map id="map" />
      {!formVisible && (
        <BottomContainer isTrips={props.isTrips}>
          <div style={{ width: "100%" }}>
            {options.canSearchPosition && (
              <>
                <Icon
                  width="13px"
                  height="19px"
                  src="/assets/icons/guides/localisation.svg"
                  left
                />
                <GoogleAutocomplete
                  placeholder={locale("nearby.explore.placeholder_search")}
                  setAddressData={(data) => handleSetSearchPosition(data)}
                  style={{ paddingLeft: "43px" }}
                />
              </>
            )}
            {props.isTrips &&
              (hasSearched ? (
                <Button
                  text={props.fixedTextTrips}
                  onClick={openTripsForm}
                  loading={false}
                  style={{
                    marginTop: "8px",
                    backgroundColor: colors.accent,
                    border: "none",
                    height: "27px",
                    width: "100%",
                    color: "#ffffff",
                  }}
                />
              ) : (
                <Button
                  text={props.fixedTextTrips}
                  onClick={() => {
                    return;
                  }}
                  loading={false}
                  style={{
                    marginTop: "8px",
                    backgroundColor: "#4e4f50",
                    border: "none",
                    height: "27px",
                    width: "100%",
                    color: "#aaaaaa",
                    cursor: "none",
                  }}
                />
              ))}
          </div>
        </BottomContainer>
      )}
      {options.canSetPosition && hasMoved && (
        <Button
          text={props.fixedText}
          onClick={props.isTrips ? openTripsForm : setNewPosition}
          loading={false}
          style={{
            marginTop: "8px",
            position: "absolute",
            backgroundColor: colors.accent,
            border: "none",
            padding: "24px 116px",
            color: "#ffffff",
            top: "calc(50% + 10px)",
            fontSize: "16px",
            fontFamily: fonts.light.name,
            fontWeight: fonts.light.weight,
            borderRadius: "4px",
          }}
        />
      )}
      {props.markerFixed && (
        <Icons
          name="explore/icons-actions-localisation-orange-shadow"
          width="37"
          height="44"
          style={{
            position: "absolute",
            top: "calc(50% - 60px)",
          }}
        />
      )}
      {formVisible && (
        <TripForm
          onClose={setFormVisible}
          coords={centerCoords}
          refresh={props.refresh}
        />
      )}
    </Container>
  );
};

export default MapContainer;

const loader = new Loader({
  apiKey: "AIzaSyDsBCYXJa6oeDZgBwkhfYhZInjkuGjRdko",
  version: "weekly",
});

const mapStyles = [
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 10;
`;

const Icon = styled.img<{ left?: boolean }>`
  position: absolute;
  z-index: 2;
  top: 13px;
  ${(p) => (p.left ? "left: 18px;" : "right: 16px;")}
  ${(p) => (p.left ? "" : "cursor: pointer;")}
`;

const Map = styled.div`
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const BottomContainer = styled.div<{ isTrips: boolean }>`
  position: absolute;
  bottom: 42px;
  width: ${(props) => (props.isTrips ? "90%" : "40%")};
  z-index: 20;
  border-radius: 4px;
  display: flex;
  justify-content: center;
`;
