import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';

import { getNearbyCategory } from '../../services/nearby.service';
import { useLogin } from '../../utils/auth.utils';
import { getQueryFilters } from './filters.utils';

import { Loader } from '../../components/style/loader.component';
import ScreenWrapper from '../../components/style/screen-wrapper.component';
import colors from '../../themes/colors-v2.theme';

import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { PrimaryButton } from '../../components/forms/primaryButton.component';
import MetaData from '../../components/seo/meta-data.component';
import { Tab } from '../../components/tabs/Tab';
import { Tabs } from '../../components/tabs/Tabs';
import NavbarSlice from '../../store/slices/navbar.slice';
import useLocale from '../../utils/locale/locale.hook';

import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import { useLocation } from 'react-router-dom';
import { Divider } from '../../components/miscellaneous/divider.component';
import { Flex } from '../../components/style/flex.component';
import { getCommunities, getSexualPractices, getSexualRole } from '../../services/caracteristics.service';
import { City, getMyCities, getPopularCities } from '../../services/city.service';
import { getMyTravels } from '../../services/travel.service';
import MeSlice from '../../store/slices/me.slice';
import { useOnClickOutside } from '../../utils/clickOutside.utils';
import { debouncedCitySearch, getCityFromPosition } from '../../utils/search.utils';
import { useRedirectUnsignedUser } from '../../utils/useRedirectUnsignedUser';
import { useScrollDisplayArrows } from '../../utils/useScroll';
import { QuickFiltersSidebar } from '../nearby/components/filters/quick-filters-sidebar.component';
import { RightSidebarAdvancedFilters } from '../nearby/components/filters/right-sidebar-filters.component';
import { useFiltersCount } from '../nearby/components/filters/useHasFilters';
import Headers from '../nearby/components/headers.component';
import NoGeolocationPermission from '../nearby/components/no-geolocation-permission.component';
import { CustomGrid } from '../nearby/components/custom-grid.component';
import ModalTrips from './components/modal-trips.component';
import { MyTrips } from './components/my-trips.component';
import { FiltersType } from '../nearby/nearby.screenv2';
import { CellType } from '../nearby/nearby.screenv2';
import { ExploreTabs } from './explore.screenv2';
import { Travel } from './explore.screenv2';

type TabName = 'travelling' | 'myTrips';
const tabs: TabName[] = ['travelling', 'myTrips'];

const DEFAULT_PARIS_COORDINATES = {
  latitude: 48.866667,
  longitude: 2.333333,
};

const TripsScreen = () => {
  useRedirectUnsignedUser();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const { isLogin, me } = useLogin();
  const dispatch = useDispatch();
  const locale = useLocale();
  const locationInputRef = useRef(null);
  const locationHistory = useLocation<{ tab?: TabName }>();

  const { exploreLatitude, exploreLongitude } = useSelector((state: any) => state.meStore);

  const [users, setUsers] = useState<{ users: any[] }>({ users: [] });
  const [coords, setCoords] = useState(null);
  const [filters, setFilters] = useState<FiltersType>({ status: ['online'] });
  const [areFiltersDeactivated, setAreFiltersDeactivated] = useState(false);
  const [displayGeoloPerm, setDisplayGeoloPerm] = useState(false);
  const [activeTab, setActiveTab] = useState<TabName>(locationHistory.state?.tab ?? 'travelling');
  const [selected, setSelected] = useState<number>(tabs.indexOf(activeTab) ?? 0);

  const [cellType, setCellType] = useState<CellType>('rectangle');
  const [hasMore, setHasMore] = useState(true);
  const [infiniteScrollKey, setInfiniteScrollKey] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<SearchResult>();
  const [isSearchResults, setIsSearchResults] = useState(false);
  const [communityChoiceOpen, setCommunityChoiceOpen] = useState(false);
  const [sexualRoleOpen, setSexualRoleOpen] = useState(false);
  const [sexualPracticesOpen, setSexualPracticesOpen] = useState(false);
  const [currentCity, setCurrentCity] = useState<City>(null);

  const [popularCities, setPopularCities] = useState(true);
  const [cities, setCities] = useState(null);
  const [hasMyCitiesData, setHasMyCitiesData] = useState(false);
  const [location, setLocation] = useState<string>('');
  const [myTrips, setMyTrips] = useState([]);
  const [headerTab, setHeaderTab] = useState<ExploreTabs>('location');
  const [tripsOpen, setTripsOpen] = useState<boolean>(false);
  const [isModifyingTrip, setIsModifyingTrip] = useState(false);
  const [trip, setTrip] = useState<Travel>();
  const [addressAutocomplete, setAddressAutocomplete] = useState<SearchResult<RawResult>[]>([]);
  const [currentCityName, setCurrentCityName] = useState('');

  const isLoadingRef = useRef(false);
  const filtersRef = useRef(null);

  const quickFiltersRef = useRef(null);
  const navbarStore = useSelector((state: any) => state.navbarStore);
  const filtersCount = useFiltersCount(filters as any);

  const { displayLeftArrow, displayRightArrow, handleScroll, handleArrowClick } = useScrollDisplayArrows(filtersRef);

  const debouncedSearch = useRef(debouncedCitySearch(setAddressAutocomplete)).current;

  const handleAddress = (address: SearchResult) => {
    setSelectedAddress(address);
    setAddressAutocomplete([]);
    handleRefresh();
  };

  const resetLocation = () => {
    setSelectedAddress(null);
    setHeaderTab('location');
    if (locationInputRef !== null) {
      locationInputRef.current.value = '';
    }
    handleRefresh();
  };

  const loadMoreProfiles = async (headerTab, refresh) => {
    if (headerTab === 'location' && (!exploreLatitude || !exploreLongitude)) {
      return;
    }
    if (isLoadingRef.current) return;
    isLoadingRef.current = true;
    if (refresh) {
      setUsers({ users: [] });
    }
    const query: any = getQueryFilters(areFiltersDeactivated ? {} : filters);
    query.limit = 100;
    query.page = refresh ? 0 : users.users.length / query.limit;
    const position = getMapPosition();
    query.latitude = position.lat;
    query.longitude = position.lng;
    const data = await getNearbyCategory('travellers', query);
    setUsers((_users) => ({ users: [...(refresh ? [] : _users.users), ...data.users] }));
    setHasMore(false);

    isLoadingRef.current = false;
  };

  const getPermission = async () => {
    // await getLocation((res) => setCoords(res), setDisplayGeoloPerm);
  };

  const refresh = () => {
    setUsers({ users: [] });
  };

  useEffect(() => {
    const tab = locationHistory.state?.tab;

    if (tab === 'myTrips') {
      setActiveTab(tab);
      setSelected(tabs.indexOf(tab) ?? 0);
    }
  }, [locationHistory.state?.tab]);

  useEffect(() => {
    if (filters !== null) {
      setInfiniteScrollKey(Math.random());
      loadMoreProfiles(headerTab, true);
    }
  }, [filters]);

  useEffect(() => {
    if (exploreLatitude && exploreLongitude) {
      setInfiniteScrollKey(Math.random());
    }
    loadMoreProfiles(headerTab, true);
  }, [exploreLatitude, exploreLongitude, headerTab, currentCity, selectedAddress]);

  useEffect(() => {
    refresh();
  }, [coords, areFiltersDeactivated]);

  useEffect(() => {
    if (!isDesktop) dispatch(NavbarSlice.actions.setFiltersOpen('close'));
  }, [isDesktop]);

  useEffect(() => {
    getPermission();
  }, []);

  useOnClickOutside([quickFiltersRef], () => {
    if (isDesktop) {
      setCommunityChoiceOpen(false);
      setSexualPracticesOpen(false);
      setSexualRoleOpen(false);
    }
  });

  const handleNewExploredCity = (city: City) => {
    if (typeof city.latitude !== 'number') city.latitude = parseFloat(city.latitude);
    if (typeof city.longitude !== 'number') city.longitude = parseFloat(city.longitude);
    setCurrentCity(city);
    setLocation(city.nameKey);
    dispatch(
      MeSlice.actions.updateMe({
        exploreLatitude: city.latitude,
        exploreLongitude: city.longitude,
      })
    );
  };

  const getDataCities = async () => {
    const popCityData = await getPopularCities();
    setPopularCities(popCityData);
    const query = {
      limit: 10, // DEBUG: pagination with scroll!
      page: 0, // DEBUG: pagination with scroll!
      random: false,
      popular: false,
      myCities: true,
    };
    const myCitiesData = await getMyCities(query);
    setCities(myCitiesData);
    // The default explored city is the first of the user's list
    // of cities or the first one of the random list of popular cities sent by the API
    if (myCitiesData.length > 0) {
      handleNewExploredCity(myCitiesData[0]);
      setHasMyCitiesData(true);
    } else {
      handleNewExploredCity(popCityData[0]);
      setHasMyCitiesData(false);
    }
  };

  const getMapPosition = useCallback(() => {
    if (selectedAddress?.raw?.lat && selectedAddress?.raw?.lon) {
      return { lat: parseFloat(selectedAddress.raw.lat), lng: parseFloat(selectedAddress.raw.lon) };
    } else {
      if (isLogin) {
        return { lat: me.latitude, lng: me.longitude };
      }
    }
    return { lat: DEFAULT_PARIS_COORDINATES.latitude, lng: DEFAULT_PARIS_COORDINATES.longitude };
  }, [selectedAddress?.raw, me.latitude, me.longitude]);

  const handleRefresh = async () => {
    getDataCities();
    getDataTrips();
  };

  const getDataTrips = async () => {
    const query = {
      limit: 50,
      page: 0,
    };
    const tripsData = await getMyTravels(query);
    setMyTrips(tripsData);
  };

  useEffect(() => {
    getDataCities();
  }, []);

  useEffect(() => {
    getDataTrips();
  }, []);

  useEffect(() => {
    async function fetchCityName() {
      const position = getMapPosition();
      setCurrentCityName(await getCityFromPosition(position.lat, position.lng));
    }
    fetchCityName();
  }, [selectedAddress?.raw, me.longitude, me.latitude]);

  if (!isLogin) {
    return <Loader />;
  }

  return (
    <ScreenWrapper>
      {displayGeoloPerm && <NoGeolocationPermission onDone={() => setDisplayGeoloPerm(false)} />}
      <MetaData title={locale('app_name')} description={locale('signin.landing.description1')} />
      {users && (
        <MainWrapper>
          <div ref={quickFiltersRef}>
            <ModalTrips
              isOpen={tripsOpen}
              onClose={() => {
                setTripsOpen(false);
                setIsModifyingTrip(false);
              }}
              refresh={handleRefresh}
              isModifyingTrip={isModifyingTrip}
              setIsModifyingTrip={setIsModifyingTrip}
              trip={trip}
              setTrip={setTrip}
              getDataTrips={getDataTrips}
            />
            <QuickFiltersSidebar
              onChange={(changeFilters) => {
                setFilters((_filters) => {
                  setAreFiltersDeactivated(() => {
                    return false;
                  });
                  return areFiltersDeactivated
                    ? { communities: changeFilters }
                    : { ..._filters, communities: changeFilters };
                });
              }}
              dataSelected={filters['communities'] || []}
              getData={async () => await getCommunities()}
              keyName={'communityKey'}
              phraseKey={'community'}
              isOpen={communityChoiceOpen}
              onClose={() => {
                setCommunityChoiceOpen(false);
                setSexualPracticesOpen(false);
                setSexualRoleOpen(false);
              }}
              isDisabled={areFiltersDeactivated}
            />
            <QuickFiltersSidebar
              onChange={(changeFilters) => {
                setFilters((_filters) => {
                  setAreFiltersDeactivated(() => {
                    return false;
                  });
                  return areFiltersDeactivated
                    ? { sexualRole: changeFilters }
                    : { ..._filters, sexualRole: changeFilters };
                });
              }}
              dataSelected={filters['sexualRole'] || []}
              getData={async () => await getSexualRole()}
              keyName={'sexualRoleKey'}
              phraseKey={'sexual_role'}
              isOpen={sexualRoleOpen}
              onClose={() => {
                setCommunityChoiceOpen(false);
                setSexualPracticesOpen(false);
                setSexualRoleOpen(false);
              }}
              isDisabled={areFiltersDeactivated}
            />
            <QuickFiltersSidebar
              onChange={(changeFilters) => {
                setFilters((_filters) => {
                  setAreFiltersDeactivated(() => {
                    return false;
                  });
                  return areFiltersDeactivated
                    ? { sexualPractices: changeFilters }
                    : { ..._filters, sexualPractices: changeFilters };
                });
              }}
              dataSelected={filters['sexualPractices'] || []}
              getData={async () => await getSexualPractices()}
              keyName={'sexualPracticeKey'}
              phraseKey={'sexual_practice'}
              isOpen={sexualPracticesOpen}
              onClose={() => {
                setCommunityChoiceOpen(false);
                setSexualPracticesOpen(false);
                setSexualRoleOpen(false);
              }}
              isDisabled={areFiltersDeactivated}
            />
          </div>
          <RightSidebarAdvancedFilters
            filters={filters}
            setFilters={setFilters}
            filtersOpen={navbarStore.filtersOpen}
            setFiltersOpen={(str: string) => dispatch(NavbarSlice.actions.setFiltersOpen(str))}
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
            setIsSearchResults={setIsSearchResults}
            areFiltersDeactivated={areFiltersDeactivated}
            setAreFiltersDeactivated={setAreFiltersDeactivated}
          />
          <Content filtersOpen={navbarStore.filtersOpen} isDesktop={isDesktop}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 16px 10px 16px',
                width: isDesktop ? 'calc(100vw - 250px)' : '100%',
                boxSizing: 'border-box',
                position: 'fixed',
                zIndex: 10,
                background: colors.darkGradient,
                backgroundAttachment: 'fixed',
                top: 0,
              }}
            >
              <Flex alignItems="center" justify="space-between" gap="16px" style={{ padding: ' 0', width: '100%' }}>
                <Flex alignItems="center" gap="16px">
                  {/* <img src="/assets/icons/sidebar/navbar/calendar-white.svg" alt="trips" width={24} height={24} /> */}
                  <img
                    onClick={() => history.go(-1)}
                    src="/assets/icons/nearby/chevron-right-white.svg"
                    alt="chevron-right"
                    width={14}
                    height={14}
                    style={{ transform: 'rotate(180deg)', padding: 8 }}
                  />
                  <div style={{ fontWeight: 600, fontSize: '16px', color: 'white' }}>{locale('trips.title')}</div>
                </Flex>
                <Flex gap="8px">
                  <PrimaryButton size="large" isActive onClick={() => setTripsOpen(true)}>
                    {locale('travel_create_action')}
                  </PrimaryButton>
                </Flex>
              </Flex>
            </div>
            <Tabs isFixed selectedTab={selected}>
              <Tab
                title={locale('trips.tab.they_travel')}
                style={{ flex: 1 }}
                onClick={() => setActiveTab('travelling')}
                titleStyle={{ display: !isDesktop ? 'none' : '' }}
              >
                <Flex
                  style={{
                    width: 'calc(100vw - 270px)',
                    position: 'fixed',
                    alignItems: 'center',
                    top: 89,
                    background: colors.darkGradient,
                    backgroundAttachment: 'fixed',
                    zIndex: 10,
                    padding: '16px 8px 16px 8px',
                    borderTop: `1px solid ${colors.grayBorder}`,
                  }}
                >
                  <CellFormatContainer>
                    <img
                      src={`/assets/icons/nearby/rectangle${cellType === 'rectangle' ? '-blue' : ''}.svg`}
                      onClick={() => setCellType('rectangle')}
                      width={24}
                      height={24}
                    />
                    <img
                      src={`/assets/icons/nearby/square${cellType === 'square' ? '-blue' : ''}.svg`}
                      onClick={() => setCellType('square')}
                      width={24}
                      height={24}
                    />
                    <img
                      src={`/assets/icons/nearby/bigSquare${cellType === 'bigSquare' ? '-blue' : ''}.svg`}
                      onClick={() => setCellType('bigSquare')}
                      width={24}
                      height={24}
                    />
                  </CellFormatContainer>
                  <Divider />
                  <FiltersListArrowWrapper onScroll={handleScroll}>
                    {displayLeftArrow ? (
                      <PrimaryButton
                        size="medium"
                        isActive={true}
                        style={{ position: 'absolute', left: 0, top: 0, zIndex: 10, transform: 'rotate(180deg)' }}
                        onClick={() => handleArrowClick('left')}
                      >
                        <img
                          src="/assets/icons/nearby/chevron-right-white.svg"
                          alt="chevron-left"
                          width={6}
                          height={6}
                        />
                      </PrimaryButton>
                    ) : null}
                    {displayRightArrow ? (
                      <PrimaryButton
                        size="medium"
                        isActive={true}
                        style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
                        onClick={() => handleArrowClick('right')}
                      >
                        <img
                          src="/assets/icons/nearby/chevron-right-white.svg"
                          alt="chevron-right"
                          width={6}
                          height={6}
                        />
                      </PrimaryButton>
                    ) : null}
                    <FiltersListWrapper ref={filtersRef}>
                      <Headers
                        setFilters={setFilters}
                        filtersOpen={navbarStore.filtersOpen}
                        setFiltersOpen={(str: string) => dispatch(NavbarSlice.actions.setFiltersOpen(str))}
                        filtersCount={filtersCount}
                        areFiltersDeactivated={areFiltersDeactivated}
                      />

                      <PrimaryButton
                        isActive={!areFiltersDeactivated && filters.status?.includes('online')}
                        onClick={() => {
                          setFilters((_filters) => {
                            setAreFiltersDeactivated(() => {
                              return false;
                            });
                            return areFiltersDeactivated && _filters.status?.includes('online')
                              ? { status: ['online'] }
                              : _filters.status?.includes('online')
                              ? { ..._filters, status: [] }
                              : { ..._filters, status: ['online'] };
                          });
                        }}
                      >
                        {locale('profile.status.connected')}
                      </PrimaryButton>

                      <PrimaryButton
                        isActive={filters.isMeetNow && !areFiltersDeactivated}
                        onClick={() =>
                          setFilters((_filters) => {
                            setAreFiltersDeactivated(() => {
                              return false;
                            });
                            return areFiltersDeactivated
                              ? { isMeetNow: true }
                              : { ..._filters, isMeetNow: !_filters.isMeetNow };
                          })
                        }
                      >
                        {locale('filters.meet_now')}
                      </PrimaryButton>

                      <PrimaryButton>Hashtags</PrimaryButton>

                      <PrimaryButton
                        isActive={filters.isNew && !areFiltersDeactivated}
                        onClick={() =>
                          setFilters((_filters) => {
                            setAreFiltersDeactivated(() => {
                              return false;
                            });
                            return areFiltersDeactivated ? { isNew: true } : { ..._filters, isNew: !_filters.isNew };
                          })
                        }
                      >
                        {locale('nearby.new_faces')}
                      </PrimaryButton>

                      <PrimaryButton
                        isActive={filters.withProfilePictureOnly && !areFiltersDeactivated}
                        onClick={() =>
                          setFilters((_filters) => {
                            setAreFiltersDeactivated(() => {
                              return false;
                            });
                            return areFiltersDeactivated
                              ? { withProfilePictureOnly: true }
                              : { ..._filters, withProfilePictureOnly: !_filters.withProfilePictureOnly };
                          })
                        }
                      >
                        {locale('radar.search.with_picture.title')}
                      </PrimaryButton>

                      <PrimaryButton
                        onClick={() => {
                          setCommunityChoiceOpen((open) => !open);
                          setSexualPracticesOpen(false);
                          setSexualRoleOpen(false);
                        }}
                        isActive={filters['communities']?.length > 0 && !areFiltersDeactivated}
                      >
                        {locale('nearby.communities')}
                        <img src="/assets/icons/arrow-down.svg" alt="arrow-down" />
                      </PrimaryButton>

                      <PrimaryButton
                        onClick={() => {
                          setSexualRoleOpen((open) => !open);
                          setCommunityChoiceOpen(false);
                          setSexualPracticesOpen(false);
                        }}
                        isActive={filters['sexualRole']?.length > 0 && !areFiltersDeactivated}
                      >
                        {locale('radar.filter.positions')}
                        <img src="/assets/icons/arrow-down.svg" alt="arrow-down" />
                      </PrimaryButton>

                      <PrimaryButton
                        onClick={() => {
                          setSexualPracticesOpen((open) => !open);
                          setCommunityChoiceOpen(false);
                          setSexualRoleOpen(false);
                        }}
                        isActive={filters['sexualPractices']?.length > 0 && !areFiltersDeactivated}
                      >
                        {locale('sexualPractices')}
                        <img src="/assets/icons/arrow-down.svg" alt="arrow-down" />
                      </PrimaryButton>
                    </FiltersListWrapper>
                  </FiltersListArrowWrapper>
                  <SearchInputWrapper>
                    <SearchInputContainer>
                      <ResetLocationSearch
                        width="9px"
                        height="13.33px"
                        src="/assets/icons/sidebar/navbar/explore-gray.svg"
                      />
                      <LocationTextInput
                        placeholder={
                          selectedAddress?.raw?.address?.city
                            ? selectedAddress.raw.address.city
                            : currentCityName
                            ? currentCityName
                            : locale('guide.form.city.label')
                        }
                        onChange={(e) => debouncedSearch(e.target.value)}
                        ref={locationInputRef}
                      />
                      {addressAutocomplete.length > 0 && (
                        <Autocomplete>
                          {addressAutocomplete.slice(0, 5).map((address, index) => (
                            <Line key={`${address.label}-${index}`} onClick={() => handleAddress(address)}>
                              {address.label}
                            </Line>
                          ))}
                        </Autocomplete>
                      )}
                    </SearchInputContainer>
                  </SearchInputWrapper>
                </Flex>

                <SectionContainer>
                  <InfiniteScroll
                    pageStart={-1}
                    loadMore={(page) => loadMoreProfiles('location', false)}
                    hasMore={hasMore}
                    key={infiniteScrollKey}
                    loader={
                      <div style={{ display: 'flex', justifyContent: 'center' }} key={Math.random().toString()}>
                        <Loader color={colors.primary} key={Math.random().toString()} />
                      </div>
                    }
                  >
                    <CustomGrid
                      users={users?.users}
                      cellType={cellType}
                      showBoostAd
                      premiumTitle={`${locale('grid.premium.title')} ${currentCityName}`}
                      showSubscribeAd={false}
                    />
                  </InfiniteScroll>
                </SectionContainer>
              </Tab>
              <Tab
                title={locale('trips.tab.my_trips')}
                titleStyle={{ display: !isDesktop ? 'none' : '' }}
                style={{ flex: 1, background: colors.darkGradient, marginTop: '-12px' }}
                onClick={() => setActiveTab('myTrips')}
              >
                <MyTrips
                  trips={myTrips}
                  getDataTrips={getDataTrips}
                  setTripsOpen={setTripsOpen}
                  setIsModifyingTrip={setIsModifyingTrip}
                  setTrip={setTrip}
                />
              </Tab>
            </Tabs>
          </Content>
        </MainWrapper>
      )}
    </ScreenWrapper>
  );
};

export default TripsScreen;

const CellFormatContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  width: fit-content;
  background: ${colors.darkBlue};
  cursor: pointer;
  padding: 1px 4px;
  border-radius: 8px;
  margin-right: 8px;
`;

const FiltersListArrowWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-left: 8px;

  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  // overflow: scroll;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FiltersListWrapper = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SectionContainer = styled.div`
  background-color: transparent;
  margin-bottom: 16px;
  margin-top: 90px;
  padding: 8px 8px 0 8px;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);

  @media (max-width: 1000px) {
    padding: 16px 0 0 0;
    border-radius: 0;
    max-width: ${`${window.innerWidth}px`};
  }
`;

const MainWrapper = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    padding-bottom: 75px;
  }
`;

const Content = styled.div<{ filtersOpen: boolean; isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  ${(p) =>
    p.isDesktop &&
    `
      width: ${p.filtersOpen ? 'calc(100% - 362px)' : '100%'};
      /* Chrome / Safari / etc. */
  `}
`;

const RoundButtonsContainer = styled.div`
  position: fixed;
  bottom: 72px;
  right: 8px;
  display: flex;
  gap: 8px;
`;

const RoundButtonContainer = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SearchInputWrapper = styled.div`
  width: calc(100vw);
  height: 40px;
  background: ${colors.darkGradient};
  background-attachment: fixed;
  position: absolute;
  top: 55px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.grayBorder};
`;

const SearchInputContainer = styled.div`
  position: absolute;
  z-index: 500;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 248px;
  box-sizing: border-box;
  border-radius: 48px;
  border: 2px solid transparent;
  background: linear-gradient(to right, #d93427, #f69101, #dfd902, #14aa5f, #2d56c5, #4c2e7b) border-box;
  color: white;
`;

const LocationTextInput = styled.input`
  padding-left: 28px;
  border-radius: 48px;
  background-color: ${colors.darkGray};
  color: ${colors.lightGrey7};
  border: 1px solid transparent;
  outline: none;
  font-size: 12px;
  box-sizing: border-box;
  height: 33.5px;
  width: 100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.lightGrey2};
  }
  :-ms-input-placeholder {
     color: ${colors.lightGrey2};
  }
`;

const ResetLocationSearch = styled.img`
  position: absolute;
  left: 10px;
  top: 11px;
  cursor: pointer;
`;

const Autocomplete = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.dark};
  border-radius: 8px;
  position: absolute;
  top: 40px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
  border: 1px solid ${colors.grayBorder};

  @media (max-width: 1000px) {
    top: 60px;
    width: 93%;
  }
`;

const Line = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${colors.darkGray};
  }
`;
