import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";

import Text from "../../../../components/style/text.component";
import Button from "../../../../components/forms/button.component";
import { Loader } from "../../../../components/style/loader.component";

import useLocale from "../../../../utils/locale/locale.hook";
import { signin, signup } from "../../../../services/auth.service";
import AuthSlice from "../../../../store/slices/auth.slice";
import { setProfilePicture } from "../../../../services/users.service";
import { setMe } from "../../../../utils/auth.utils";
import { uploadFile } from "../../../../utils/s3.utils";
import { addPictures } from "../../../../services/pictures.service";
import ModalSlice from "../../../../store/slices/modal.slice";
import colors from "../../../../themes/colors.theme";
import fonts from "../../../../themes/fonts.theme";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { environment } from "../../../../environments";

enum SignUpStatus {
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

interface CongratulationProps {
  // onNext: () => void;
  data: {
    pseudo: string;
    birthdate: Date;
    email: string;
    password: string;
    picture: File;
    locationOptin: boolean;
    phone: string;
    phoneVerificationCode: string;
    captchaResponse: string;
  };
  onPrev: () => void;
}

export default function Congratulation(props: CongratulationProps) {
  const locale = useLocale();
  const dispatch = useDispatch();
  const history = useHistory();
  const localeStore = useSelector((state: any) => state.localeStore);
  const [preview] = useState(
    props?.data.picture && URL.createObjectURL(props?.data.picture)
  );
  const [status, setStatus] = useState(SignUpStatus.LOADING);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitResponse, setSubmitResponse] = useState<any>();

  const submit = async () => {
    const body = {
      email: props.data.email,
      password: props.data.password,
      pseudo: props.data.pseudo,
      birthdate: props.data.birthdate,
      locationOptin: props.data.locationOptin,
      language: localeStore.language,
      phone: props.data.phone,
      phoneVerificationCode: props.data.phoneVerificationCode,
      captchaResponse: props.data.captchaResponse,
    };
    const res = await signup(body);
    setSubmitResponse(res);

    if (!res?.error) {
      setStatus(SignUpStatus.SUCCESS);
    } else {
      setStatus(SignUpStatus.ERROR);
    }
  }

  const signinAfterSubmit = async () => {
    if (submitResponse && !submitResponse?.error) {
      const res = await signin(props.data.pseudo, props.data.password);
      if (res.jwt) {
        const cookies = new Cookies();
        cookies.set("token", res.jwt, {
          domain: environment.cookieDomain,
          path: "/",
          secure: true,
        });
        localStorage.setItem("token", res.jwt);
        dispatch(AuthSlice.actions.setToken(res.jwt));
        if (props.data.picture) {
          const { path, error } = await uploadFile(props.data.picture);
          // DEBUG: error not handled
          if (error) return;

          try {
            const pictures = await addPictures([
              {
                path,
                album: res.user.albums.find(
                  (album: any) => album.type === "myPhotos"
                ),
              },
            ]);
            await setProfilePicture(pictures[0].id);
          } catch (e) {
            // Picture is not valid
          }
        }
        setMe();
      }
      history.replace("/nearby");
    } else {
      setErrorMsg(locale(submitResponse.error) || locale("signup.error"));
    }
  };

  useEffect(() => {
    submit();
  }, []);

  return (
    <FormWrapper>
      <ContentWrapper>
        {status === SignUpStatus.LOADING && (
          <div style={{ margin: "12px auto" }}>
            <Loader
              size={100}
              color={colors.primary}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        )}
        {status === SignUpStatus.SUCCESS && (
          <>
            <Text
              style={{
                fontSize: 18,
                fontWeight: fonts.bold.weight,
                textAlign: "center",
                marginBottom: 8,
              }}
            >
              {locale("signup.congratulation.h1", {
                nickname: props.data.pseudo,
              })}
            </Text>
            <Text style={{ marginBottom: 16, fontSize: 18 }}>
              {locale("signup.congratulation.newest")}
            </Text>
            {preview && (
              <div
                style={{
                  margin: "12px auto",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <ImageProfile alt="" src={preview} />
              </div>
            )}
            <MarginWrapper>
              <Button
                text={locale("global.close")}
                onClick={() => {
                  signinAfterSubmit();
                  dispatch(ModalSlice.actions.setSignup({ visible: false }));
                }}
                style={{ width: "100%", borderRadius: 8 }}
              />
            </MarginWrapper>
          </>
        )}
        {status === SignUpStatus.ERROR && (
          <div style={{ margin: "12 px auto" }}>
            <Text style={{ marginBottom: 32 }}>{errorMsg}</Text>
            <Button
              text={locale("snackbar.error.try_again")}
              style={{ width: "100%", borderRadius: 8 }}
              onClick={() => {
                props.onPrev();
              }}
            />
          </div>
        )}
      </ContentWrapper>
    </FormWrapper>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  margin: auto;
`;

const MarginWrapper = styled.div`
  margin-bottom: 24px;

  & > * {
    width: 100%;
  }
`;

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px;
  background-color: ${colors.dark};
  border-radius: 16px;
  width: 369px;
`;

const ImageProfile = styled.img`
  width: 180px;
  height: 180px;
  object-fit: contain;
  border-radius: 4px;
`;
