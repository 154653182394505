import { useState, useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components/macro';
import { FancyScrollbar } from '../../../components/style/fancy-scrollbar.style';
import useLocale from '../../../utils/locale/locale.hook';
import InfiniteScroller from 'react-infinite-scroller';
import { useLogin } from '../../../utils/auth.utils';
import { getAllUserLikedMyPictures } from '../../../services/users.service';
import { NoConversations } from './no-conversations.component';
import { CustomGrid } from '../../nearby/components/custom-grid.component';

export const LikesList = () => {
  const locale = useLocale();
  const { isLogin } = useLogin();
  const likesRef = useRef(null);

  const [hasMoreLikes, setHasMoreLikes] = useState(false);
  const [scrollerKey, setScrollerKey] = useState(Math.random());
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [hasNoLikes, setHasNoLikes] = useState(true);
  const [lastLikes, setLastLikes] = useState([]);

  const refreshUserLikes = () => {
    setHasMoreLikes(false);
    if (setHasNoLikes) {
      setHasNoLikes(false);
    }
    setShouldResetPagination(true);
    getUserLikes(0, 20).then((lastLikes) => {
      if (Array.isArray(lastLikes)) {
        setShouldResetPagination(false);
        setHasMoreLikes(lastLikes.length === 20);
        setLastLikes(lastLikes);
        if (setHasNoLikes) {
          setHasNoLikes(lastLikes.length === 0);
        }
      }
    });
  };

  const getUserLikes = async (page: number | string, limit: number | string) => {
    const res = await getAllUserLikedMyPictures();
    if (!Array.isArray(res) || res.length === 0) {
      setHasMoreLikes(false);
      return;
    }
    const newLikes = lastLikes.concat(res);
    setLastLikes(newLikes);

    if (res.length === 20) {
      setTimeout(() => {
        setHasMoreLikes(true);
      }, 500);
    }
  };

  useEffect(() => {
    if (shouldResetPagination) {
      setScrollerKey(Math.random());
    }
  }, [shouldResetPagination]);

  useImperativeHandle(likesRef, () => ({
    refreshLikes: () => {
      refreshUserLikes();
    },
  }));

  useEffect(() => {
    if (isLogin) {
      refreshUserLikes();
    }
  }, [isLogin]); // eslint-disable-line

  const getMoreLikes = async (page: number | string, limit: number | string) => {
    setHasMoreLikes(false);
    getUserLikes(page, limit);
  };

  return (
    <LikesWrapper key={scrollerKey}>
      {hasNoLikes ? (
        <NoConversations iconePath="/assets/icons/messages/heart.svg" title={locale('messages.empty.likes')} />
      ) : (
        <div style={{ marginTop: 8 }}>
          <InfiniteScroller
            pageStart={0}
            loadMore={getMoreLikes}
            hasMore={hasMoreLikes}
            useWindow={false}
            initialLoad={false}
          >
            <CustomGrid users={lastLikes} cellType="square" showSubscribeAd={false} />
          </InfiniteScroller>
        </div>
      )}
    </LikesWrapper>
  );
};

const LikesWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 8px;
  padding: 4px 5px;
  ${FancyScrollbar}

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 1000px) {
    margin-bottom: 80px;
  }
`;
