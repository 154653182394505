import { del, put, post, get, queryBuilder } from './utils.service';

export const createUserVideo = async (video) => {
  const res = await post(`/user-videos`, video);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const deleteUserVideo = async (video) => {
  const res = await del(`/user-videos`, video);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getUserPresentationVideo = async (userId: string) => {
  const res = await get(`/user-videos/presentation/${userId}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
