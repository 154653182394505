import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import Button from "../../../../components/forms/button.component";
import PasswordInput from "../../../../components/forms/password.input";
import TextInput from "../../../../components/forms/text.input";
import Text from "../../../../components/style/text.component";

import { Cropper } from "react-cropper";
import { useDispatch, useSelector } from "react-redux";
import Datepicker from "../../../../components/forms/datepicker.component";
import { PrimaryButton } from "../../../../components/forms/primaryButton.component";
import Image from "../../../../components/image.component";
import { Flex } from "../../../../components/style/flex.component";
import { environment } from "../../../../environments";
import { emailIsFree, pseudoIsFree } from "../../../../services/users.service";
import ModalSlice from "../../../../store/slices/modal.slice";
import colors from "../../../../themes/colors-v2.theme";
import fonts from "../../../../themes/fonts.theme";
import { isSexual } from "../../../../utils/blacklist.utils";
import { getFileFromDataURL } from "../../../../utils/file.utils";
import { getCurrentLanguage } from "../../../../utils/locale/hreflang.utils";
import useLocale from "../../../../utils/locale/locale.hook";
import {
  calculatePasswordStrength,
  validateEmail,
} from "../../../../utils/regex.util";
import { removeFirstWord } from "../../../../utils/string.utils";
import { registerSignupAttempt } from "../../../../services/auth.service";

import Castle from "@castleio/castle-js";
import SelectPictureModal from "../../../../components/miscellaneous/modals/select-picture.modal";

const castle = Castle.configure({ pk: environment.castle.publishableKey });

interface CredentialsProps {
  onPrev: () => void;
  onNext: (data: any) => void;
  values: {
    pseudo: string;
    email: string;
    password: string;
    date: Date;
    pictureSelected: File;
    picture: File;
  };
}

enum Mode {
  SELECT_PICTURE = "SELECT_PICTURE",
  ADJUST_PICTURE = "ADJUST_PICTURE",
  DISPLAY_PICTURE = "DISPLAY_PICTURE",
}

const defaultErrorMessage = {
  pseudo: "",
  email: "",
  password: "",
  birthdate: "",
};

export default function Credentials(props: CredentialsProps) {
  const locale = useLocale();

  const [pseudo, setPseudo] = useState(props.values.pseudo || "");
  const [email, setEmail] = useState(props.values.email || "");
  const [password, setPassword] = useState(props.values.password || "");
  const [validPseudo, setValidPseudo] = useState(
    (props.values.pseudo || "").length > 3
  );
  const [validEmail, setValidEmail] = useState(
    validateEmail(props.values.email || "") || false
  );
  const [validPassword, setValidPassword] = useState(
    calculatePasswordStrength(props.values.password || "") > 6
  );
  const [errorMsg, setErrorMsg] =
    useState<typeof defaultErrorMessage>(defaultErrorMessage);

  const [signupErrorMsg, setSignupErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [birthdate, setBirthdate] = useState<Date>(props.values.date || null);
  const [validDate, setValidDate] = useState(props.values.date !== null);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [fileBeforeCrop, setFileBeforeCrop] = useState<File>(
    props.values.pictureSelected || null
  );
  const [imageSrcBeforeCrop, setImageSrcBeforeCrop] = useState<string>(null);
  const [file, setFile] = useState<File>(props.values.picture || null);
  const [imageSrc, setImageSrc] = useState<string>(null);
  const [mode, setMode] = useState(Mode.SELECT_PICTURE);
  const [validPicture, setValidPicture] = useState(
    props.values.pictureSelected !== null
  );
  const [pseudoError, setPseudoError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const dispatch = useDispatch();

  const linkTerms = () => {
    switch (getCurrentLanguage()) {
      case "en":
        return "https://www.bearwww.com/blog/bearwww-tos-terms-of-use/";
      case "fr":
        return "https://www.bearwww.com/blog/bearwww-conditions-generales-utilisation/";
      case "it":
        return "https://www.bearwww.com/blog/bearwww-condizioni-uso/";
      case "es":
        return "https://www.bearwww.com/blog/bearwww-terminos-y-condiciones/";
      case "ca":
        return "https://www.bearwww.com/blog/bearwww-terminos-y-condiciones/";
      case "pt":
        return "https://www.bearwww.com/blog/bearwwww-termos-de-utilizacao/";
      case "el":
        return "https://www.bearwww.com/blog/bearwww-tos-terms-of-use/";
      case "ja":
        return "https://www.bearwww.com/blog/bearwww-tos-terms-of-use/";
      case "nl":
        return "https://www.bearwww.com/blog/bearwww-gebruiksvoorwaarden/";
      case "de":
        return "https://www.bearwww.com/blog/bearwww-nutzungsbedingungen/";
      case "pl":
        return "https://www.bearwww.com/blog/bearwww-warunki-uzytkowania/";
    }
  };

  const onChangeEmail = (text: string) => {
    if (validateEmail(text)) {
      setValidEmail(true);
      setEmailError(false);
    } else {
      setValidEmail(false);
    }
    setEmail(text);
  };

  const onChangePassword = (text: string, isValid: boolean) => {
    setValidPassword(isValid);
    setPasswordError(false);
    setPassword(text);
  };

  const onChangePseudo = (text: string) => {
    const textFormated = text.trim();
    if (textFormated.length > 3) {
      setValidPseudo(true);
      setPseudoError(false);
    } else {
      setValidPseudo(false);
    }
    setPseudo(textFormated);
  };

  const onBlurPseudo = async () => {
    if (pseudo.length < 3) {
      setPseudoError(true);
    } else {
      const isPseudoFree = await pseudoIsFree(pseudo);
      if (!isPseudoFree) {
        setValidPseudo(false);
        setPseudoError(true);
        setErrorMsg((errorMsg) => ({
          ...errorMsg,
          pseudo: locale("signup.pseudo.already_taken"),
        }));
        setLoading(false);
        return;
      }
      const isSexualPseudo = isSexual(pseudo);
      if (isSexualPseudo) {
        setValidPseudo(false);
        setErrorMsg((errorMsg) => ({
          ...errorMsg,
          pseudo: locale("signup.pseudo.sexual_pseudo"),
        }));
        setLoading(false);
        return;
      }
      setErrorMsg((errorMsg) => ({ ...errorMsg, pseudo: "" }));
      setSignupErrorMsg("");
    }
  };

  const onBlurEmail = async () => {
    if (!validateEmail(email)) {
      setEmailError(true);
    } else {
      const isEmailFree = await emailIsFree(email);
      if (!isEmailFree) {
        setValidEmail(false);
        setEmailError(true);
        setErrorMsg((errorMsg) => ({
          ...errorMsg,
          email: locale("signup.credentials.email_already_use"),
        }));
        setLoading(false);
        return;
      }
      setErrorMsg((errorMsg) => ({ ...errorMsg, email: "" }));
      setSignupErrorMsg("");
    }
  };

  const onBlurPassword = (isValid: boolean) => {
    if (!isValid) {
      setPasswordError(true);
    } else {
      setErrorMsg((errorMsg) => ({ ...errorMsg, password: "" }));
      setSignupErrorMsg("");
    }
  };

  const onChangeDate = (date: Date) => {
    setErrorMsg((errorMsg) => ({ ...errorMsg, birthdate: "" }));
    setSignupErrorMsg("");
    if (date !== null) {
      setBirthdate(date);
      setValidDate(true);
    } else {
      setValidDate(false);
    }
  };

  const getAge = (birthDate: Date) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const onNextHandler = async () => {
    setLoading(true);
    const isPseudoFree = await pseudoIsFree(pseudo);
    if (!isPseudoFree) {
      setValidPseudo(false);
      setErrorMsg((errorMsg) => ({
        ...errorMsg,
        pseudo: locale("signup.pseudo.already_taken"),
      }));
      setLoading(false);
      return;
    }

    const isEmailFree = await emailIsFree(email);
    if (!isEmailFree) {
      setValidEmail(false);
      setErrorMsg((errorMsg) => ({
        ...errorMsg,
        email: locale("signup.credentials.email_already_use"),
      }));
      setLoading(false);
      return;
    }

    // const isPhoneFree = await phoneIsFree(phone);
    // if (!isPhoneFree) {
    //   setValidPhone(false);
    //   setErrorMsg((errorMsg) => ({ ...errorMsg, phone: locale('signup.credentials.phone_already_use') }));
    //   setLoading(false);
    //   return;
    // }

    const isSexualPseudo = isSexual(pseudo);
    if (isSexualPseudo) {
      setValidPseudo(false);
      setErrorMsg((errorMsg) => ({
        ...errorMsg,
        pseudo: locale("signup.pseudo.sexual_pseudoo"),
      }));
      setLoading(false);
      return;
    }

    const age = getAge(birthdate);
    if (age < 18) {
      setErrorMsg((errorMsg) => ({
        ...errorMsg,
        birthdate: locale("signup.birthdate.too_young"),
      }));
      setLoading(false);
      return;
    }

    const castleRequestToken = await castle.createRequestToken();
    const res = await registerSignupAttempt(pseudo, email, castleRequestToken);
    if (!res.success) {
      setSignupErrorMsg('An error occured, please try again later');
      setLoading(false);
      return;
    }

    setLoading(false);
    props?.onNext({
      pseudo,
      email,
      password,
      birthdate,
      fileBeforeCrop,
      file,
    });
  };

  const rotateHandler = (rotation: "clockwise" | "counterclockwise") => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    if (cropper) {
      cropper.rotate(rotation === "clockwise" ? 90 : 270);
    }
  };

  const selectFileHandler = (pictures: File[]) => {
    // TODO: Do not use Adjust_Picture to avoid scroll problem on mode mobile
    const [pictureSelected] = pictures;
    setFileBeforeCrop(pictureSelected);
    setMode(Mode.ADJUST_PICTURE);
  };

  const validateAdjust = () => {
    const imageElement: any = cropperRef.current;
    const cropper: any = imageElement?.cropper;
    if (cropper) {
      const fileAdjusted = getFileFromDataURL(
        cropper.getCroppedCanvas().toDataURL(),
        "crop.png"
      );
      setFile(fileAdjusted);
      setMode(Mode.DISPLAY_PICTURE);
      setValidPicture(true);
    }
  };

  const modalStore = useSelector((state: any) => state.modalStore);


  useEffect(() => {
    if (!!props.values.picture && !!props.values.pictureSelected) {
      setMode(Mode.DISPLAY_PICTURE);
    }
  }, [props.values]);

  useEffect(() => {
    if (fileBeforeCrop) {
      setImageSrcBeforeCrop(URL.createObjectURL(fileBeforeCrop));
    } else {
      setFile(null);
      setImageSrc(null);
    }
  }, [fileBeforeCrop]);

  useEffect(() => {
    if (file) {
      setImageSrc(URL.createObjectURL(file));
    }
  }, [file]);

  const isFormValid = validPseudo && validEmail && validPassword && validDate;

  return (
    <>
      <FormWrapper>
        <LoginHeader>
          <HeaderLogoWrapper>
            <Image
              src="/assets/icons/logo/logo-white-with-text.svg"
              alt="Bearwww logo"
              width={"185px"}
              height={"35px"}
            />
          </HeaderLogoWrapper>
          <HeaderTitleWrapper>
            <Text
              style={{ fontSize: 14, fontWeight: fonts.bold.weight }}
              dangerouslySetInnerHTML={{
                __html: locale(
                  "signin.header.connect_to_bears_and_their_friends"
                ),
              }}
            />
          </HeaderTitleWrapper>
        </LoginHeader>
        <MarginWrapper>
          <TextInput
            placeholder={locale("signup.credentials.pseudo")}
            value={pseudo || ""}
            success={validPseudo}
            onChange={onChangePseudo}
            onBlur={onBlurPseudo}
            maxLength={15}
            error={pseudoError}
          />
          {errorMsg.pseudo ? (
            <ErrorMessage>
              <Text style={{ color: colors.error }}>{errorMsg.pseudo}</Text>
            </ErrorMessage>
          ) : null}
        </MarginWrapper>

        <MarginWrapper>
          <TextInput
            placeholder={locale("signup.credentials.email")}
            value={email}
            success={validEmail}
            onChange={onChangeEmail}
            onBlur={onBlurEmail}
            error={emailError}
          />
          {errorMsg.email ? (
            <ErrorMessage>
              <Text style={{ color: colors.error, fontWeight: 400 }}>
                {errorMsg.email}
              </Text>
            </ErrorMessage>
          ) : null}
        </MarginWrapper>
        <MarginWrapper>
          <PasswordInput
            placeholder={locale("signup.credentials.password")}
            value={password}
            success={validPassword}
            onChange={onChangePassword}
            onBlur={onBlurPassword}
            error={passwordError}
          />
        </MarginWrapper>
        <Text
          style={{
            marginBottom: 8,
            marginTop: 16,
            textAlign: "left",
            fontWeight: 500,
            fontSize: 16,
          }}
        >
          {locale("signup.birthdate.description")}
        </Text>
        <Datepicker
          value={birthdate}
          error={!!(errorMsg.birthdate == locale("signup.birthdate.too_young"))}
          onChange={onChangeDate}
        />
        {modalStore.selectPictures && (
          <SelectPictureModal visible={modalStore.selectPictures.visible} />
        )}
        {mode === Mode.SELECT_PICTURE && (
          <SelectPictureContainer
            onClick={() => {
              dispatch(
                ModalSlice.actions.setSelectPictures({
                  visible: true,
                  type: "file",
                  onSelectedFiles: selectFileHandler,
                })
              );
            }}
          >
            <UploadIconContainer>
              <Image
                src="/assets/icons/upload.svg"
                alt="Bearwww logo"
                width={"40px"}
                height={"40px"}
                style={{}}
              />
            </UploadIconContainer>
            <TextContainer>
              <Text
                style={{
                  color: colors.darkBlue,
                  fontWeight: fonts.bold.weight,
                }}
              >
                {locale("android.profile.picture.upload").split(" ")[0]}
              </Text>
              <Text>
                {removeFirstWord(locale("android.profile.picture.upload"))}
              </Text>
            </TextContainer>{" "}
          </SelectPictureContainer>
        )}
        {mode === Mode.ADJUST_PICTURE && (
          <CropperWrapper>
            <Cropper
              src={imageSrcBeforeCrop}
              style={{ width: "100%" }}
              aspectRatio={environment.profilePicture?.ratio}
              viewMode={1}
              guides={true}
              ref={cropperRef}
            />
            <AdjustFooter>
              <div>
                <Image
                  alt="rotate clockwise"
                  onClick={() => rotateHandler("clockwise")}
                  src="/assets/icons/auth/rotate-clockwise.svg"
                  style={{ marginRight: 12, cursor: "pointer" }}
                  width={"20px"}
                  height={"20px"}
                />
                <Image
                  alt="rotate counter clockwise"
                  onClick={() => rotateHandler("counterclockwise")}
                  src="/assets/icons/auth/rotate-counterclockwise.svg"
                  width={"20px"}
                  height={"20px"}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <Button
                text={locale("valider")}
                onClick={validateAdjust}
                style={{
                  width: "75%",
                  borderRadius: 8,
                }}
              />
            </AdjustFooter>
          </CropperWrapper>
        )}
        {mode === Mode.DISPLAY_PICTURE && (
          <BoxWrapper>
            <Box>
              <CropPreview alt="" width="100" height="100" src={imageSrc} />
              <EditButton
                alt=""
                src="/assets/icons/auth/close-filled-white.svg"
                onClick={() => setMode(Mode.SELECT_PICTURE)}
              />
            </Box>
          </BoxWrapper>
        )}

        <Text style={{ marginTop: 24, fontSize: 10 }}>
          {locale("signup.credentials.confirm_read_conditions")}{" "}
          <Text
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: 10,
              display: "inline-block",
            }}
          >
            <a href={linkTerms()}>{locale("title_cgu")}</a>
          </Text>
        </Text>

        {true && (
          <Text style={{ color: colors.error, marginTop: 8 }}>
            {signupErrorMsg}
          </Text>
        )}

        <PrimaryButton
          size="large"
          disabled={!isFormValid}
          isActive={isFormValid}
          onClick={onNextHandler}
          style={{ marginTop: 24, width: "100%" }}
        >
          {locale("signup.signup")}
        </PrimaryButton>

        <Flex
          alignItems="center"
          style={{ marginTop: 24, gap: 4, alignSelf: "center" }}
        >
          <Text>{locale("signup.already_have_account")}</Text>
          <Text
            bold
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={props.onPrev}
          >
            {locale("login.login")}
          </Text>
        </Flex>
      </FormWrapper>
    </>
  );
}

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  background-color: ${colors.dark};
  border-radius: 16px;
  width: 363px;
`;

const MarginWrapper = styled.div`
  margin-bottom: 8px;
  width: 100%;
`;

const ErrorMessage = styled.div`
  margin-top: 6px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: auto;

  & > button {
    border-radius: 8px;
    width: 100%;
  }
`;

const LoginHeader = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > * {
    line-height: normal;
    margin-bottom: 0;
  }
`;

const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 16px;

  @media (max-width: 1000px) {
    text-align: center;
    padding: 0 16px;
  }
`;

const SelectPictureContainer = styled.div`
  border: 1px solid ${colors.lightGrey7};
  border-radius: 12px;
  width: 100%;
  padding: 16px 0;
  margin-top: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UploadIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${colors.lightGrey12};
  margin-bottom: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const AdjustFooter = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CropperWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
`;

const BoxWrapper = styled.div`
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
  height: 172px;
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 172px;
  height: 172px;
  border-radius: 12px;
`;

const CropPreview = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

const EditButton = styled.img`
  cursor: pointer;
  user-select: none;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 10px;
  right: 10px;
`;

const Centered = styled.div`
  text-align: center;
`;

const SkipLink = styled.div`
  text-decoration: underline;
  color: #101113;
  margin-top: 22px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
`;
