import { useEffect, useState } from 'react';
import useLocale from '../../../../../utils/locale/locale.hook';
import { Tags } from '../../../../../components/settings/tags.component';
import { TitleOptions } from '../title-option.component';
import styled from 'styled-components/macro';
import { Flex } from '../../../../../components/style/flex.component';
import { PrimaryButton } from '../../../../../components/forms/primaryButton.component';
import { useMediaQuery } from 'react-responsive';

type MultipleAdvancedProps = {
  onChange: (data) => void;
  dataSelected: string[];
  getData: () => Promise<any>;
  keyName: string;
  phraseKey: string;
  title?: string;
  filters?: any;
  categoryName?: string;
  isDisabled?: boolean;
};

const MultipleAdvanced = (props: MultipleAdvancedProps) => {
  const [dataSelected, setDataSelected] = useState(props.dataSelected || []);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(dataSelected.length > 0);
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const title = props.title || locale('nearby.advanced_filter.' + props.phraseKey);

  const onSelect = (item) => {
    const dataSelectedCopy = [...dataSelected];
    const index = dataSelectedCopy.findIndex((_item) => _item === item);
    if (index !== -1) {
      dataSelectedCopy.splice(index, 1);
    } else {
      dataSelectedCopy.push(item);
    }
    setDataSelected(dataSelectedCopy);
    props.onChange(dataSelectedCopy);
  };

  const getData = async () => {
    const _data = await props.getData();
    setData(_data);
  };

  useEffect(() => {
    setDataSelected(props.dataSelected);
  }, [props.dataSelected]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <Flex
        style={{ cursor: 'pointer', gap: 8, marginBottom: 16 }}
        alignItems="center"
        onClick={() => setIsOpen((open) => !open)}
      >
        {isOpen && !props.isDisabled ? (
          <img src="/assets/icons/nearby/checkbox-blue.svg" alt="checkbox" />
        ) : (
          <img src="/assets/icons/nearby/checkbox.svg" alt="checkbox" width={24} height={24} />
        )}
        <TitleOptions>{title}</TitleOptions>
      </Flex>

      {isOpen && (
        <Tags>
          {data.map((dataItem) => {
            return (
              <PrimaryButton
                key={dataItem[props.keyName]}
                onClick={() => !props.isDisabled && onSelect(dataItem[props.keyName])}
                isActive={dataSelected.includes(dataItem[props.keyName]) && !props.isDisabled}
                style={{ margin: 4 }}
              >
                {locale(props.phraseKey + '.name.' + dataItem[props.keyName])}
              </PrimaryButton>
            );
          })}
        </Tags>
      )}
    </Container>
  );
};

export default MultipleAdvanced;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: auto;
  margin-top: 18px;
  position: relative;
`;
