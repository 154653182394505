import { get, post, del, put } from './utils.service';

type Guide = {
  address: string;
  city: string;
  description: string;
  email: string;
  end_datetime: string;
  id: string;
  latitude: string;
  longitude: string;
  organizer: string;
  owner: any;
  participants: [];
  picture: Record<string, unknown>;
  slug: string;
  start_datetime: string;
  ticket: string;
  title: string;
  type: string;
  venue: string;
  website: string;
};

export const createGuide = async (body: any) => {
  const res = await post('/guides', body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getGuides = async ({
  type,
  mode,
  sort,
  latitude,
  longitude,
  state,
}: {
  type: string;
  mode: string;
  sort: string | null;
  latitude?: number;
  longitude?: number;
  state?: string;
}): Promise<Guide[]> => {
  let queryUrl = `/guides?type=${type}&mode=${mode}`;
  if (!!sort) {
    queryUrl += `&sort=${sort}`;
  }
  if (!!latitude && !!longitude) {
    queryUrl += `&latitude=${latitude}`;
    queryUrl += `&longitude=${longitude}`;
  }
  if (!!state) {
    queryUrl += `&state=${state}`;
  }
  const res = await get(queryUrl);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getGuideBySlug = async (guideSlug: string) => {
  const res = await get(`/guides/slug/${guideSlug}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const toggleParticipation = async (
  guideId: string,
  body: {
    status: string;
  }
) => {
  const res = await post(`/guides/${guideId}/toggle-participation`, body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const deleteGuide = async (guideId: string) => {
  const res = await del(`/guides/${guideId}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const updateGuide = async (guideId: string, body: any) => {
  const res = await put(`/guides/${guideId}`, body);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const getNumberParticipants = async (guideId: string) => {
  const res = await get(`/guides/${guideId}/numberOfParticipants`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
