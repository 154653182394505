import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components/macro";

import { deleteConversation } from "../../../services/chat.service";
import colors from "../../../themes/colors-v2.theme";
import fonts from "../../../themes/fonts.theme";
import useLocale from "../../../utils/locale/locale.hook";
import { PrimaryButton } from "../../../components/forms/primaryButton.component";
import { UserModalTemplate } from "../../user-profile/components/user-modal.component";

enum DeleteStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
}

interface ModalProps {
  visible: boolean;
  closeModal: () => void;
  conversation: any;
  deleteCallback?: () => void;
}

export default function DeleteConversationModal({
  visible,
  closeModal,
  conversation,
  deleteCallback,
}: ModalProps) {
  const [status, setStatus] = useState(DeleteStatus.IDLE);
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });

  const deleteConversationHandler = async () => {
    if (status === DeleteStatus.LOADING) {
      return;
    }

    setStatus(DeleteStatus.LOADING);
    const res = await deleteConversation(conversation.id);
    if (res?.success) {
      if (!!deleteCallback) {
        deleteCallback();
      }
      closeModal();
    } else {
      setStatus(DeleteStatus.ERROR);
    }
  };

  useEffect(() => {
    if (!visible) {
      setStatus(DeleteStatus.IDLE);
    }
  }, [visible]);

  return (
    <UserModalTemplate
      isOpen={visible}
      onClose={closeModal}
      style={{
        maxWidth: "500px",
        width: "90%",
        borderRadius: 16,
        height: "fit-content",
        background: colors.darkGray,
        position: "absolute",
        top: 80,
        border: `1px solid ${colors.grayBorder}`,
        padding: "14px 24px 14px 24px",
      }}
    >
      <ModalBody>
        <MarginWrapper>
          <Title>{locale("chat.delete_conversation")}</Title>
        </MarginWrapper>
        <MarginWrapper>
          <Disclaimer>{locale("delete_conformation")}</Disclaimer>
        </MarginWrapper>
        <ButtonsWrapper>
          <PrimaryButton size="large" onClick={closeModal}>
            {locale("cancel")}
          </PrimaryButton>
          <PrimaryButton
            size="large"
            onClick={deleteConversationHandler}
            isActive
          >
            {locale("account.photo_management.delete.alert.label")}
          </PrimaryButton>
        </ButtonsWrapper>
      </ModalBody>
    </UserModalTemplate>
  );
}

const ModalBody = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;

const MarginWrapper = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.p`
  width: 100%;
  font-size: 18px;
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.white};
  margin: 0;
  text-align: center;
`;

const Disclaimer = styled.p`
  margin: 0;
  font-size: 13px;
  color: ${colors.white};
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    width: 48%;
  }
`;

const Button = styled.div<{ outlined?: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 10px 32px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 2px 5px;
  ${(p) => p.outlined && "background-color: transparent;"}
  font-size: 14px;
`;
