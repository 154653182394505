import styled from 'styled-components/macro';

import fonts from '../../themes/fonts.theme';
import Icons from '../style/icons.component';
import { Loader } from '../style/loader.component';
import Text from '../style/text.component';
import colors from '../../themes/colors-v2.theme';

interface ContainerProps {
  outlined: boolean;
  disabled: boolean;
  small: boolean;
  round: boolean;
  padding: string;
}

export const Container = styled.button<ContainerProps>`
  border: none;
  outline: none;
  user-select: none;
  height: 32px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  background-color: ${colors.darkBlue};
  border: 1px solid ${colors.darkBlue};
  color: #fff;
  transition: all 200ms ease;
  padding: 5px 32px;
  box-sizing: border-box;

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    border-color: #a0a6b1;
    background-color: #a0a6b1;
    color: #fff;
  `}

  ${(props) =>
    props.small &&
    `
    height: auto;
    width: auto;
    padding: 1px 13px;
    border-radius: 4px;
  `}

  ${(props) => (props.padding ? `padding: ${props.padding};` : ``)};

  ${(props) =>
    props.outlined &&
    `
      color: ${colors.darkBlue};
      background-color: transparent;
    `}

  ${(props) =>
    props.round &&
    `
        border-radius: 50px;
      `}

  :hover {
    background-color: ${colors.darkBlue};
    border: 1px solid ${colors.darkBlue};

    ${(props) =>
      props.outlined &&
      `
      color: ${colors.darkBlue};
      background-color: transparent;
    `}
  }
`;

interface ButtonProps {
  text: string;
  color?: 'primary' | 'secondary' | 'light' | 'grey' | 'transparent' | 'danger';
  outlined?: boolean;
  style?: any;
  onClick?: () => void;
  loading?: boolean;
  small?: boolean;
  disabled?: boolean;
  iconLeft?: string;
  round?: boolean;
  padding?: string;
}

const Button = (props: ButtonProps) => {
  return (
    <Container
      disabled={props?.disabled}
      outlined={props.outlined}
      small={props?.small}
      style={props.style}
      onClick={props.onClick}
      round={props.round}
      padding={props.padding}
    >
      {props.loading ? (
        <Loader color={props.outlined ? colors.darkBlue : colors.dark} />
      ) : (
        <>
          {props?.iconLeft && (
            <Icons name={props?.iconLeft} width="20" height="20" style={{ marginRight: props.text ? 11 : 0 }} />
          )}
          {props.text && (
            <Text
              style={{
                color: 'inherit',
                fontWeight: 600,
                fontSize: props.style?.fontSize || 12,
                fontFamily: props.style?.fontFamily || fonts.regular.name,
              }}
            >
              {props.text}
            </Text>
          )}
        </>
      )}
    </Container>
  );
};

export default Button;
