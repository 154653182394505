import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';

import { Loader } from '../../../components/style/loader.component';
import Text from '../../../components/style/text.component';

import useLocale from '../../../utils/locale/locale.hook';
import { useOnClickOutside } from '../../../utils/clickOutside.utils';

import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';

interface Props {
  status: 'interested' | 'going' | 'not_interested' | null;
  loading: boolean;
  onSelect: (value: string) => void;
  style?: any;
}

const mapStatusToIconPath = {
  going: '/assets/icons/guides/participation/guides-icons-going.svg',
  interested: '/assets/icons/guides/participation/guides-icons-interested.svg',
  not_interested: '/assets/icons/guides/participation/guides-icons-not-interested.svg',
};

const mapStatusToIconPathDark = {
  going: '/assets/icons/guides/participation/guides-icons-going.svg',
  interested: '/assets/icons/guides/participation/guides-icons-interested.svg',
  not_interested: '/assets/icons/guides/participation/guides-icons-not-interested.svg',
};

export default function ParticipationDropdowner({ status, style, loading, onSelect }: Props) {
  const locale = useLocale();
  const [open, setOpen] = useState(false);

  const MenuRef = useRef(null);

  useOnClickOutside([MenuRef], () => {
    setOpen(false);
  });

  const onSelectHandler = (status: any) => {
    onSelect(status);
  };

  const mapStatusToLabel = {
    going: locale('guide.participation.going'),
    interested: locale('guide.participation.interested'),
    not_interested: locale('guide.participation.not_interested'),
  };

  const outlined = status === 'going' || status === 'interested';
  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        setOpen((prevState) => !prevState);
      }}
      style={style}
      outlined={outlined}
      ref={MenuRef}
    >
      {loading ? (
        <Loader color={'#d8dadc'} />
      ) : (
        <>
          <Label outlined={outlined}>{mapStatusToLabel[status] || locale('guide.participation.participate')}</Label>
          <img
            src="/assets/icons/nearby/chevron-right-white.svg"
            alt="radar"
            width={12}
            height={12}
            style={
              open
                ? { transform: 'rotate(90deg)', cursor: 'pointer', transition: 'all 400ms' }
                : { transform: 'rotate(-90deg)', transition: 'all 400ms' }
            }
          />
          {open && (
            <Menu>
              <MenuItem onClick={(e) => onSelectHandler('interested')}>
                <img alt="" src={mapStatusToIconPathDark['interested']} className="icon" />
                <Text>{mapStatusToLabel['interested']}</Text>
              </MenuItem>
              <MenuItem onClick={(e) => onSelectHandler('going')}>
                <img alt="" src={mapStatusToIconPathDark['going']} className="icon" />
                <Text>{mapStatusToLabel['going']}</Text>
              </MenuItem>
              <MenuItem onClick={(e) => onSelectHandler('not_interested')}>
                <img alt="" src={mapStatusToIconPathDark['not_interested']} className="icon" />
                <Text>{mapStatusToLabel['not_interested']}</Text>
              </MenuItem>
            </Menu>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div<{ outlined?: boolean }>`
  border-radius: 8px;
  padding: 0px 10px;
  min-width: 170px;
  height: 40px;
  background-color: ${colors.darkBlue};
  border: 1px solid rgba(0, 0, 0, 0.15);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  position: relative;

  & > .icon {
    margin-right: 8px;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 7px;
  right: 15.5px;
`;

const Label = styled.p<{ outlined?: boolean }>`
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: ${fonts.semiBold.name};
  font-weight: 600;
  color: white;
`;

const Menu = styled.div`
  position: absolute;
  background-color: #1a1a1b;
  top: 40px;
  left: 0px;
  width: calc(100% - 2px);
  z-index: 100;
  border-radius: 4px;
  max-height: 300px;
  border: solid 1px ${colors.grayBorder};
`;

const MenuItem = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  background-color: ${colors.dark}
  border-bottom: solid 1px ${colors.grayBorder};
  &:hover {
    background-color: ${colors.grayBorder};
  }
  & > img {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }
  & > p {
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
    font-size: 12px;
    color: white;
  }
`;
