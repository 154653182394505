import { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';

import { InlineForm } from './inline-form.component';
import PasswordInput from '../../../../components/forms/password.input';
import TextInput from '../../../../components/forms/text.input';
import Text from '../../../../components/style/text.component';

import { updatePseudo } from '../../../../services/auth.service';
import useLocale from '../../../../utils/locale/locale.hook';
import fonts from '../../../../themes/fonts.theme';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import { useNotification } from '../../../../utils/useNotification';

type PseudoProps = {
  me?: any;
  onChange?: (pseudo: string) => void;
  setIsSuccess?: Dispatch<SetStateAction<boolean>>;
};

const PseudoGestion = (props: PseudoProps) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [errorMsg, setErrorMsg] = useState(null);
  const [pseudo, setPseudo] = useState('');
  const [password, setPassword] = useState('');
  const { triggerNotification } = useNotification();

  const submit = async () => {
    setErrorMsg(null);
    const res = await updatePseudo(pseudo, password);
    if (res?.error) {
      if (res?.error === 'global.error.unauthorized') {
        setErrorMsg('wrong_password_entered');
      } else {
        setErrorMsg(res?.error);
      }
      return;
    }
    props?.onChange(pseudo);
    triggerNotification({
      title: locale('settings.modification_success'),
    });

    setPseudo('');
    setPassword('');
  };

  return (
    <Container>
      <SectionTitle>{locale('account.account_gestion.pseudo_title')}</SectionTitle>
      <InlineForm style={!isDesktop ? { display: 'flex', flexDirection: 'column', gap: 12 } : null}>
        <TextInput
          value={pseudo}
          onChange={setPseudo}
          maxLength={15}
          placeholder={locale('signin.placeholder.choose_new_pseudo')}
          inputBorderRadius={8}
          style={{
            gridColumn: 1,
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          }}
        />
        <PasswordInput
          value={password}
          onChange={setPassword}
          placeholder={locale('signup.credentials.confirm_password')}
          style={{
            gridColumn: 2,
          }}
          borderRadius={8}
        />
        {errorMsg && <Error>{locale(errorMsg)}</Error>}
      </InlineForm>
      <PrimaryButton
        isActive
        size="large"
        disabled={!password || password.length <= 1 || !pseudo || pseudo.length <= 2}
        onClick={() => submit()}
        style={{ height: 36, marginTop: 8 }}
      >
        {locale('account.account_gestion.submit')}
      </PrimaryButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionTitle = styled(Text)`
  font-weight: ${fonts.bold.weight};
  font-size: 14px;
  margin-bottom: 12px;
  font-family: ${fonts.bold.name};
`;

const Error = styled.div`
  grid-column: 1;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  background-color: #ff5050;
  margin: 15px 0 8px;
  padding: 12px 14px;
  font-size: 12px;
`;

export default PseudoGestion;
