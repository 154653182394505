import React from 'react';
import styled from 'styled-components/macro';
import useLocale from '../../../../utils/locale/locale.hook';
import { TextOptions } from './text-option.component';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0px 16px 0;
  cursor: pointer;
  font-family: SegoeUI;
`;

const WhiteCircle = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
`;

type StatusFilterProps = {
  onChange: (status) => void;
  style?: any;
  statusSelected?: string[];
  isDisabled?: boolean;
};

const StatusFilter = (props: StatusFilterProps) => {
  const locale = useLocale();
  const [statusSelected, setStatusSelected] = React.useState(props.statusSelected || []);

  const onSelect = (status) => {
    const statusSelectedCopy = [...statusSelected];
    const index = statusSelectedCopy.findIndex((_status) => _status === status);
    if (index !== -1) {
      statusSelectedCopy.splice(index, 1);
    } else {
      statusSelectedCopy.push(status);
    }
    setStatusSelected(statusSelectedCopy);
    props.onChange(statusSelectedCopy);
  };

  React.useEffect(() => {
    setStatusSelected(props.statusSelected || []);
  }, [props.statusSelected]);

  return (
    <Container onClick={() => !props.isDisabled && onSelect('online')}>
      <div style={{ marginRight: 8, display: 'flex' }}>
        {statusSelected.includes('online') && !props.isDisabled ? (
          <img src="/assets/icons/nearby/checkbox-blue.svg" alt="checkbox" width={24} height={24} />
        ) : (
          <img src="/assets/icons/nearby/checkbox.svg" alt="checkbox" width={24} height={24} />
        )}
      </div>
      <TextOptions disabled={false}>{locale('account.status.online')}</TextOptions>
    </Container>
  );
};

export default StatusFilter;
