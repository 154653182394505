import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux'

import useLocale from '../../../../utils/locale/locale.hook';

import { useLogin } from '../../../../utils/auth.utils';

import ModalSlice from '../../../../store/slices/modal.slice';

import fonts from '../../../../themes/fonts.theme';
import colors from '../../../../themes/colors-v2.theme';
import { CustomGrid } from '../../../nearby/components/custom-grid.component';

interface ComponentProps {
  participants: any[];
}

export default function GuideParticipants({ participants }: ComponentProps) {
  const locale = useLocale();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [participantsGoing, setParticipantsGoing] = useState([]);
  const [participantsInterested, setParticipantsInterested] = useState([]);
  const [showGoing, setShowGoing] = useState(true);

  const { me, isLogin } = useLogin();

  const showSignin = () => {
    dispatch(ModalSlice.actions.setSigninVisible(true));
  };

  const showSignup = () => {
    dispatch(ModalSlice.actions.setSigninVisible(false));
    dispatch(ModalSlice.actions.setSignup({ visible: true }));
  };

  useEffect(() => {
    setParticipantsGoing(
      participants.filter((participant) => participant.status === 'going').map((participant) => participant.user)
    );
    setParticipantsInterested(
      participants.filter((participant) => participant.status === 'interested').map((participant) => participant.user)
    );
  }, [participants]);

  return (
    <Wrapper>
      <Header notLogin={!isLogin}>
        <HeaderButton active={showGoing} onClick={() => setShowGoing(true)}>
          {locale('guide.participation.options.going')}
        </HeaderButton>
        <HeaderButton active={!showGoing} onClick={() => setShowGoing(false)}>
          {locale('guide.participation.options.interested')}
        </HeaderButton>
      </Header>
      <Main notLogin={!isLogin}>
        {showGoing ? (
          <CustomGrid
            users={participantsGoing}
            size={isDesktop ? 3 : undefined}
            cellType="square"
            strictSize={isDesktop}
            showSubscribeAd={false}
          />
        ) : (
          <CustomGrid
            users={participantsInterested}
            size={isDesktop ? 3 : undefined}
            cellType="square"
            strictSize={isDesktop}
            showSubscribeAd={false}
          />
        )}
      </Main>
      {!isLogin && (
        <LogDisplay>
          <p className="main">{locale('guide.login.title')}</p>
          <button className="bottom" onClick={showSignup}>
            <p>{locale('account.register')}</p>
          </button>
          <button onClick={showSignin}>
            <p>{locale('signin.login')}</p>
          </button>
        </LogDisplay>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12px);

  padding: 16px;

  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100%;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
    padding: 0;
  }
`;

const Header = styled.div<{ notLogin: boolean }>`
  width: 100%;
  height: 41px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 17px 15px 15px 16px;
  padding: 4px;
  border-radius: 8px;
  gap: 8px;
  box-sizing: border-box;

  ${(p) => p.notLogin && 'filter: blur(10px);'}
  ${(p) => p.notLogin && 'pointer-events: none;'}

  @media (max-width: 1000px) {
    margin: 16px;
    box-sizing: border-box;
    width: calc(100% - 32px);
  }
`;

const HeaderButton = styled.button<{ active: boolean }>`
  border: none;
  cursor: pointer;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : colors.darkGrey1)};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.darkBlue}` : '')};
  width: 100%;
`;

const Main = styled.div<{ notLogin: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  margin: 8px auto 8px auto;
  padding: 4px;

  ${(p) => p.notLogin && 'filter: blur(10px);'}
  ${(p) => p.notLogin && 'pointer-events: none;'}

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1000px) {
    margin: 0;
  }
`;

const LogDisplay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  background-color: ${colors.white};
  border-radius: 4px;
  top: calc(50% - 50px);
  left: calc(50% - 125px);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid ${colors.lightGrey9};
  padding: 16px;
  box-sizing: border-box;

  & > p.main {
    margin: 0;
    color: ${colors.black};
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
    font-size: 12px;
    margin-bottom: 12px;
  }

  & > button {
    cursor: pointer;
    padding: 8px 0px;
    width: 200px;
    border-radius: 4px;
    background-color: ${colors.darkOrange1};
    border: none;

    & > p {
      margin: 0;
      font-family: ${fonts.bold.name};
      font-weight: ${fonts.bold.weight};
      font-size: 10px;
      color: #ffffff;
    }
  }

  & button.bottom {
    cursor: pointer;
    padding: 8px 0px;
    width: 200px;
    border-radius: 4px;
    background-color: ${colors.white};
    border: 1px solid ${colors.lightGrey9};
    margin-bottom: 8px;

    & > p {
      margin: 0;
      font-family: ${fonts.bold.name};
      font-weight: ${fonts.bold.weight};
      font-size: 10px;
      color: ${colors.black};
    }
  }

  @media (max-width: 1000px) {
    width: 300px;
    left: calc(50% - 150px);
  }
`;
