import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import DetailedCard from './guide-detail-card.component';

import { getGuideBySlug } from '../../../../services/guide.service';
import useLocale from '../../../../utils/locale/locale.hook';
import { useLogin } from '../../../../utils/auth.utils';
import colors from '../../../../themes/colors-v2.theme';
import { Flex } from '../../../../components/style/flex.component';

const mapApiTypeToHref = {
  event: '/guide/events',
  bar_night_club: '/guide/bars',
  restaurant: '/guide/restaurants',
  shop: '/guide/shopping',
  association: '/guide/associations',
  accommodation: '/guide/accommodations',
  art_culture: '/guide/art-culture',
};

interface ComponentProps {
  openSubmitEventModal: () => void;
  openDeleteGuideModal: (guide: any) => void;
  openUpdateGuideModal: (guide: any) => void;
  shouldRefreshData: boolean;
  setShouldRefreshData: Function;
}

export default function GuideDisplayer({
  openDeleteGuideModal,
  openUpdateGuideModal,
  shouldRefreshData,
  setShouldRefreshData,
}: ComponentProps) {
  const locale = useLocale();
  const history = useHistory();
  const { me } = useLogin();
  const [displayedGuide, setDisplayedGuide] = useState(null);
  const params = useParams() as { guideSlug?: string };

  /**
   * Fonction permettant de mettre à jour le status de l'utilisateur s'il vient de changer sa participation.
   */
  const updateGuideParticipationStatus = (newStatus: string) => {
    const copiedGuide = JSON.parse(JSON.stringify(displayedGuide));
    if (newStatus === 'leaving') {
      copiedGuide.participants = copiedGuide.participants.filter((participant: any) => participant.user?.id !== me.id);
    } else {
      const participant = copiedGuide.participants.find((participant: any) => participant.user?.id === me.id);
      if (participant) {
        participant.status = newStatus;
      } else {
        copiedGuide.participants.push({ status: newStatus, user: me });
      }
    }
    setDisplayedGuide(copiedGuide);
  };

  useEffect(() => {
    if (shouldRefreshData) {
      getGuideBySlug(params.guideSlug).then((guide) => {
        if (!guide.error && !guide.message) {
          setDisplayedGuide(guide);
        } else {
          history.push('/guide/events');
        }
      });
      setShouldRefreshData(false);
    }
  }, [shouldRefreshData]);

  useEffect(() => {
    if (params.guideSlug) {
      getGuideBySlug(params.guideSlug).then((guide) => {
        if (!guide.error && !guide.message) {
          setDisplayedGuide(guide);
        } else {
          history.push('/guide/events');
        }
      });
    }
  }, []); // eslint-disable-line

  // mapApiTypeToHref[displayedGuide?.type];

  return (
    <Wrapper>
      <Header>
        <Flex alignItems="center" gap="16px" onClick={history.goBack} style={{ cursor: 'pointer' }}>
          <img
            src="/assets/icons/nearby/chevron-right-white.svg"
            alt="radar"
            width={16}
            height={16}
            style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
          />
          <div style={{ fontWeight: 600, fontSize: '16px', color: 'white' }}>{locale('guide.back')}</div>
        </Flex>
      </Header>
      {displayedGuide && (
        <DetailedCard
          guide={displayedGuide}
          updateMyParticipation={updateGuideParticipationStatus}
          openDeleteGuideModal={openDeleteGuideModal}
          openUpdateGuideModal={openUpdateGuideModal}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0px 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${colors.primary};
`;
