import React, { useMemo } from 'react';

export const useFiltersCount = (filters: Record<string, string | unknown[]>) => {
  return useMemo(() => {
    return Object.values(filters).reduce((acc, filter) => {
      if (Array.isArray(filter)) {
        acc += filter.length > 0 ? 1 : 0;
      } else {
        acc += filter ? 1 : 0;
      }
      return acc;
    }, 0);
  }, [filters]);
};
