import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import ModalTemplate from "../../../components/miscellaneous/modal-template.component";
import Captcha from "./components/captcha.component";
import Congratulation from "./components/congratulation.component";
import Credentials from "./components/credentials.component";
import Location from "./components/location.component";
import PhoneVerification from "./components/phoneverification.component";

import { useMediaQuery } from "react-responsive";
import Image from "../../../components/image.component";
import { sendVerificationCode } from "../../../services/phone-verification.service";
import ModalSlice from "../../../store/slices/modal.slice";
import colors from "../../../themes/colors-v2.theme";

interface SignupProps {
  visible: boolean;
}

export default function SignupModal({ visible }: SignupProps) {
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const initialPseudo = useSelector(
    (state: any) => state.modalStore.signup.initialPseudo
  );
  const [currentStep, setCurrentStep] = useState(0);

  const [birthdate, setBirthdate] = useState<Date>(null);
  const [pseudo, setPseudo] = useState(initialPseudo);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [locationOptin, setLocationOptin] = useState(false);
  const [pictureSelected, setPictureSelected] = useState<File>(null);
  const [picture, setPicture] = useState<File>(null);
  const [phone, setPhone] = useState<string>("");
  const [captchaResponse, setCaptchaResponse] = useState("");
  const [phoneVerificationCode, setPhoneVerificationCode] =
    useState<string>("");
  const dispatch = useDispatch();

  const onNext = () => {
    // DEBUG: hardcoded and outdated
    if (currentStep < 15) {
      setCurrentStep(currentStep + 1);
    }
  };

  const backToSignin = () => {
    dispatch(ModalSlice.actions.setSignup({ visible: false }));
    dispatch(ModalSlice.actions.setSigninVisible(true));
    setCurrentStep(0);
  }

  const onPrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      closeModalHandler();
    }
  };

  const closeModalHandler = () => {
    dispatch(ModalSlice.actions.setSignup({ visible: false }));
  };

  useEffect(() => {
    if (visible) {
      if (currentStep >= 4) {
        setCurrentStep(0);
      }
    } else {
      setCurrentStep(0);
    }
  }, [visible]);

  const steps = [
    <Credentials
      onPrev={backToSignin}
      key="credentials"
      values={{
        pseudo,
        email,
        password,
        date: birthdate,
        pictureSelected,
        picture,
      }}
      onNext={(data) => {
        setPseudo(data.pseudo);
        setEmail(data.email);
        setPassword(data.password);
        setBirthdate(data.birthdate);
        setPictureSelected(data.fileSelected);
        setPicture(data.file);
        onNext();
      }}
    />,
    <Location
      key="location"
      onNext={(locationOptin) => {
        setLocationOptin(locationOptin);
        onNext();
      }}
    />,
    <Captcha
      key="captcha"
      values={phone}
      onNext={async (data) => {
        const token = data.token;

        setPhone(data.phone);
        setCaptchaResponse(token);
        onNext();

        try {
          await sendVerificationCode(data.phone, token);
        } catch (error) {
          console.log("API phone SMS error : ", error);
        }
      }}
    />,
    <PhoneVerification
      key="phone-verification"
      phone={phone}
      token={captchaResponse}
      onNext={(code) => {
        setPhoneVerificationCode(code);
        onNext();
      }}
    />,
    <Congratulation
      key="congratulation"
      data={{
        pseudo,
        birthdate,
        email,
        password,
        picture,
        locationOptin,
        phone,
        phoneVerificationCode,
        captchaResponse,
      }}
      onPrev={onPrev}
    />,
  ];

  return (
    <ModalTemplate
      visible={visible}
      closeModal={closeModalHandler}
      width="100vw"
      canCloseModalUsingOverlay={false}
      mobileWidth="100vw"
      closeOutside
      position={{ top: "50%", left: "50%" }}
      style={{
        backgroundImage: "url('/assets/imgs/signin/signin.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <ContentWrapper
        style={{
          height: "100vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <InsideContainer>
          {currentStep < 5 && (
            <BackButton onClick={onPrev} isDesktop={isDesktop}>
              <Image
                src="/assets/icons/arrow-left-black.svg"
                alt="Arrow back"
                width={"14px"}
                height={"14px"}
                style={{}}
              />
            </BackButton>
          )}
          <FormBackgroundWrapper>{steps[currentStep]}</FormBackgroundWrapper>
        </InsideContainer>
      </ContentWrapper>
    </ModalTemplate>
  );
}

const FormBackgroundWrapper = styled.div`
  position: relative;
  background: linear-gradient(
    to right,
    #d93427,
    #f69101,
    #dfd902,
    #14aa5f,
    #2d56c5,
    #4c2e7b
  );
  border-radius: 16px;
  padding: 1px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  & input {
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    &::placeholder {
      color: ${colors.darkGrey1};
    }
  }
`;

const InsideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  minheight: 100%;
`;

const BackButton = styled.div<{ isDesktop: boolean }>`
  position: fixed;
  top: ${({ isDesktop }) => (isDesktop ? "30px" : "50px")};
  left: ${({ isDesktop }) => (isDesktop ? "91px" : "24px")};
  cursor: pointer;
  z-index: 2;
`;

const Header = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    line-height: normal;
    margin-bottom: 0;
  }
`;

const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 15px;
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 16px;
`;

const StepContainer = styled.div`
  padding: 24px 32px;
  background-color: ${colors.white};
  border-radius: 16px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-self: center;
  box-sizing: border-box;
`;
