import { useState } from 'react';
import styled from 'styled-components/macro';
import colors from '../../themes/colors.theme';
import useLocale from '../../utils/locale/locale.hook';
import { useMediaQuery } from 'react-responsive';
import Icons from '../style/icons.component';
import MapContainer from '../map-container.component';
import { useLogin } from '../../utils/auth.utils';
import { useDispatch } from 'react-redux';
import MeSlice from '../../store/slices/me.slice';
import { getCityAndCountry } from '../../utils/google-maps.utils';
import { addACity } from '../../services/city.service';
import { useNotifContext, ADD } from '../../context/notif-context';
import { Coordinates } from '../../pages/explore/explore.screenv2';

// DEBUG: remove the any
interface MapExploreProps {
  onClose?: () => void;
  style: any;
  styleMap?: any;
  styleMobile?: any;
  isTrips?: any;
  refresh?: () => void;
  onNewCity?: () => void;
  exploreLatitude?: number;
  exploreLongitude?: number;
}

const MapExplore = (props: MapExploreProps) => {
  const { me } = useLogin();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const locale = useLocale();
  const dispatch = useDispatch();
  const [coords, setCoords] = useState<Coordinates>(
    props.exploreLatitude && props.exploreLongitude
      ? { latitude: props.exploreLatitude, longitude: props.exploreLongitude }
      : { latitude: me?.latitude, longitude: me?.longitude }
  );

  const { notifDispatch } = useNotifContext();

  const setNewExploreCity = async () => {
    const latitude = Math.round(coords.latitude * 10000) / 10000;
    const longitude = Math.round(coords.longitude * 10000) / 10000;
    if (latitude == me.latitude && longitude == me.longitude) {
      props.onClose();
      return;
    }
    const { city, country, cityName, countryName } = await getCityAndCountry(coords.latitude, coords.longitude);
    if (!city || !country) return;

    const response: any = await addACity({
      cityName,
      cityPlaceId: city.place_id,
      countryName,
      countryPlaceId: country.place_id,
      latitude: city.geometry.location.lat().toString(),
      longitude: city.geometry.location.lng().toString(),
    });
    if (response.error) {
      // DEBUG: handle the error here
      props.onClose();
      return;
    }

    notifDispatch({
      type: ADD,
      payload: {
        content: `${cityName} ${locale('explore.addCityNotif')}`,
      },
    });

    dispatch(
      MeSlice.actions.updateMe({
        exploreLatitude: coords.latitude,
        exploreLongitude: coords.longitude,
        city,
      })
    );

    props.onNewCity();
    props.onClose();
  };

  const Map = (
    <MapContainer
      center={coords}
      zoom={13}
      onSetNewPosition={() => setNewExploreCity()}
      onChangeCoords={(coords) => setCoords(coords)}
      markerFixed
      fixedText={props.isTrips ? locale('travel.goThere') : locale('explore.here')}
      fixedTextTrips={locale('global.create')}
      autocomplete
      isTrips={props.isTrips}
      refresh={props.refresh}
    />
  );

  return isDesktop ? (
    <Container style={props.style}>
      <Content style={props.styleMap}>{Map}</Content>
    </Container>
  ) : (
    <ContainerMobile style={props.styleMobile}>
      <Close onClick={() => props.onClose()}>
        <Icons name="cross-filled-grey" width="25" height="25" />
      </Close>
      <Content>{Map}</Content>
    </ContainerMobile>
  );
};

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${colors.backgroundDark};
  z-index: 5;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerMobile = styled.div`
  position: fixed;
  width: calc(100% - 24px - 24px);
  height: calc(100% - 24px - 24px - 56px);
  top: 0px;
  background-color: ${colors.backgroundDark};
  z-index: 100;
  padding: 24px 24px 24px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: grey;
  border-radius: 4px;
  overflow: hidden;
`;

const Close = styled.div`
  position: absolute;
  top: 34px;
  right: 34px;
  z-index: 30;
  cursor: pointer;
`;

export default MapExplore;
