import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type Props = {
  src: string;
  title: string;
  style?: React.CSSProperties;
};

export const VideoCustom = ({ src, title, style }: Props) => {
  const cloudfrontSrc = src?.replace(
    'bearwww-uploads-staging.s3.eu-west-3.amazonaws.com',
    'd2tiq3wo24jtl5.cloudfront.net'
  );

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progressValue, setProgressValue] = useState(0);
  const [showControls, setShowControls] = useState(true);
  let timeoutId: NodeJS.Timeout;

  const handlePlay = () => {
    if (isVideoInFullScreen()) {
      return;
    }
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPlaying(!videoRef.current.paused);
    }
  };

  const isVideoInFullScreen = () => {
    return document.fullscreenElement === videoRef.current;
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setProgressValue((videoRef.current.currentTime / videoRef.current.duration) * 100);
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (videoRef.current) {
      videoRef.current.currentTime = (parseFloat(e.target.value) / 100) * videoRef.current.duration;
      setProgressValue(parseFloat(e.target.value));
    }
  };

  const handleMouseEnter = () => {
    setShowControls(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setShowControls(false);
    }, 500);
  };

  const handleMouseMove = () => {
    if (!showControls) {
      setShowControls(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setShowControls(false);
      }, 500);
    }
  };

  const handleFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      }
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
      setDuration(videoRef.current.duration);
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  return (
    <VideoContainer
      onClick={handlePlay}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      style={style}
    >
      <Video ref={videoRef} onPlay={() => setIsPlaying(true)} onPause={() => setIsPlaying(false)}>
        <source src={cloudfrontSrc} type="video/mp4" />
        {title}
      </Video>
      <PlayButton src="/assets/icons/messages/lecture.svg" width={36} height={36} alt="play" visible={!isPlaying} />
      <BottomContainer style={{ opacity: showControls ? 1 : 0, transition: 'opacity 0.3s' }}>
        <ProgressBar
          min={0}
          max={duration}
          value={progressValue}
          onChange={handleSeek}
          onClick={(e) => e.stopPropagation()}
        />
        <div onClick={handleFullScreen} style={{ cursor: 'pointer', margin: '0 8px' }}>
          <img src="/assets/icons/messages/fullscreen.svg" width={20} height={20} />
        </div>
      </BottomContainer>
    </VideoContainer>
  );
};

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Video = styled.video`
  width: 100%;
`;

const PlayButton = styled.img<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: blue;
  pointer: cursor;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 12px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const ProgressBar = styled.input.attrs({ type: 'range' })`
  height: 5px;
  left: -1px;
  width: 100%;
`;
