import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { initiatePrivateConversation, sendQuickshare } from '../../../../services/chat.service';
import { getMyAlbums } from '../../../../services/users.service';
import fonts from '../../../../themes/fonts.theme';

import useLocale from '../../../../utils/locale/locale.hook';
import { useHistory } from 'react-router-dom';
import { closeProfile } from '../../profile.utils';
import { UserModalTemplate } from '../user-modal.component';
import colors from '../../../../themes/colors-v2.theme';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';
import Text from '../../../../components/style/text.component';
import { Flex } from '../../../../components/style/flex.component';
import { useMediaQuery } from 'react-responsive';

enum SendStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

enum AskStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

interface ComponentProps {
  user: any;
  onClose: () => void;
}

export default function QuickshareMenu({ user, onClose }: ComponentProps) {
  const locale = useLocale();
  const history = useHistory();
  const [askStatus, setAskStatus] = useState(AskStatus.IDLE);
  const [sendStatus, setSendStatus] = useState(SendStatus.LOADING);
  const [canSendQuickshare, setCanShareQuickshare] = useState<boolean>(null);
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  const askQuickshareHandler = async () => {
    if (askStatus === AskStatus.LOADING || sendStatus === SendStatus.LOADING) {
      return;
    }

    setAskStatus(AskStatus.LOADING);
    const conversationRes = await initiatePrivateConversation(user.id);

    if (conversationRes?.conversationId) {
      const res = await sendQuickshare({
        conversationId: conversationRes?.conversationId,
        status: 'asked',
      });
      if (res?.success) {
        onClose();
      } else {
        setAskStatus(AskStatus.ERROR);
      }
    }
  };

  const sendQuickshareHandler = async () => {
    if (askStatus === AskStatus.LOADING || sendStatus === SendStatus.LOADING) {
      return;
    }

    setSendStatus(SendStatus.LOADING);
    const conversationRes = await initiatePrivateConversation(user.id);

    if (conversationRes?.conversationId) {
      const res = await sendQuickshare({
        conversationId: conversationRes?.conversationId,
        status: 'accepted',
      });
      if (res?.success) {
        onClose();
      } else {
        setSendStatus(SendStatus.ERROR);
      }
    }
  };

  const addQuicksharePicturesHandler = () => {
    closeProfile();
    history.push('/settings/pictures/quickshare');
  };

  useEffect(() => {
    getMyAlbums().then((res) => {
      if (res?.quickshare) {
        setCanShareQuickshare(res.quickshare.pictures.length > 0);
      } else {
        setCanShareQuickshare(false);
      }
      setSendStatus(SendStatus.IDLE);
    });
  }, []);

  return (
    <UserModalTemplate
      isOpen={true}
      onClose={() => onClose()}
      style={{
        borderRadius: 8,
        width: isDesktop ? '400px' : '95%',
        height: 'fit-content',
        background: colors.darkGray,
        position: 'absolute',
        paddingBottom: 16,
        bottom: 80,
        border: `1px solid ${colors.lightGrey10}`,
      }}
    >
      <Content>
        <PrimaryButton size="medium" style={{ width: 56, height: 56 }}>
          <img alt="" src="/assets/icons/sidebar/navbar/lock-album.svg" height={24} width={24} />
        </PrimaryButton>

        {canSendQuickshare ? (
          <Text bold fontSize="16px">
            {locale('quickshare.send.text')}
          </Text>
        ) : (
          <Flex direction="column" alignItems="center" justify="center" gap="16px">
            <Text bold fontSize="16px">
              {locale('quickshare.no_quickshare')}
            </Text>
            <Text>{locale('quickshare.no_quickshare-text1')}</Text>
            <Text>{locale('quickshare.no_quickshare-text2')}</Text>
          </Flex>
        )}

        <PrimaryButton
          size="large"
          isActive={true}
          onClick={canSendQuickshare ? sendQuickshareHandler : addQuicksharePicturesHandler}
        >
          {canSendQuickshare ? locale('quickshare.send.button') : locale('pictures.add')}
        </PrimaryButton>

        <Text bold fontSize="16px">
          {locale('quickshare.ask.text')}
        </Text>
        <PrimaryButton
          size="large"
          isActive={true}
          onClick={askQuickshareHandler}
          style={{ width: !isDesktop ? '90%' : '' }}
        >
          {locale('quickshare.ask.button')}
        </PrimaryButton>
      </Content>
    </UserModalTemplate>
  );
}

const Content = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 29px 0 18px;
`;

const MenuTitle = styled.div`
  border: 1px solid ${colors.graySeparator};
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  color: ${colors.darkGrey};
  margin-bottom: 16px;

  & > img {
    width: 24px;
    height: 24px;
  }

  & > p {
    text-transform: uppercase;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  text-align: center;
  color: ${colors.darkGrey};
  margin-bottom: 24px;

  & .bold {
    font-family: ${fonts.bold.name};
    font-weight: ${fonts.bold.weight};
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 8px 6px;
  border-radius: 4px;
  border: solid 1px ${colors.darkGrey};
  justify-content: center;
  margin-top: 12px;

  & > p {
    margin: 0;
    font-size: 12px;
    letter-spacing: 1.5px;
    color: ${colors.darkGrey};
    font-family: ${fonts.bold.name};
    font-weight: ${fonts.bold.weight};
    text-transform: uppercase;
  }

  &:hover {
    background-color: #d8dadc;

    & > p {
      color: #101113;
    }
  }
`;
