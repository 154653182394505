import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { createContext, useContext } from 'react';

const OpenStreetMapsContext = createContext(undefined);

const OpenStreetMapsProvider = ({ children }) => {
  const contextValue = new OpenStreetMapProvider({
    params: {
      addressdetails: 1,
    },
  });

  return <OpenStreetMapsContext.Provider value={contextValue}>{children}</OpenStreetMapsContext.Provider>;
};

export { OpenStreetMapsProvider };
