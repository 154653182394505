import { post, get, queryBuilder } from "./utils.service";

export interface Country {
  uid: string;
  nameKey: string;
  placeId: string;
}

export interface City {
  uid: string;
  nameKey: string;
  country: Country;
  placeId: string;
  latitude: number;
  longitude: number;
  picturePath?: string;
  popularCity: boolean;
}

interface CityError {
  error: string;
}

export const getPopularCities = async () => {
  const res = await get(
    `/city?page=0&limit=10&popular=true&myCities=false&random=true`
  );
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const getMyCities = async (query) => {
  const res = await get(`/city${queryBuilder(query)}`);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};

export const addACity = async (city): Promise<City | CityError> => {
  const res = await post(`/city`, city);
  const payload = await res.json();
  return payload.status === "success"
    ? payload.data
    : { error: payload.message };
};
