export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export function validDaily(url) {
  let p = /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/;
  if (url.match(p)) {
    return RegExp.$2;
  }
  p =
    /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/;
  if (url.match(p)) {
    return RegExp.$2;
  }
  return false;
}

export function validBucketUrl(url) {
  const p = /(s3-|s3\.)?(.*)\.amazonaws\.com.*/;
  return p.test(url) || url?.includes("d2tiq3wo24jtl5.cloudfront.net");
}

export function validYT(url) {
  let p =
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=((\w|-){11}))(?:\S+)?$/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  p =
    /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  return false;
}

export const validWebsite = (value) => {
  return value.match(
    /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,5}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,5}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
};

export function validPornhub(url) {
  let p =
    /^(?:https?:\/\/)?(?:[a-z]{2}\.)?pornhub\.com\/view_video\.php\?viewkey=((\w|-){8,15})(?:\S+)?$/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  p =
    /^(?:https?:\/\/)?(?:[a-z]{2}\.)?pornhub\.com\/(?:watch|embed)\/(\d+)/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  return false;
}

export function validXvideos(url) {
  let p =
    /^(?:https?:\/\/)?(?:w{3}\.)?(?:[a-z]{2}\.)?xvideos\.com\/video([0-9]+)\/.*$/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  p =
    /^(?:https?:\/\/)?(?:w{3}\.)?(?:[a-z]{2}\.)?xvideos\.com\/embedframe\/([0-9]+)$/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  return false;
}

export function validXhamster(url) {
  let p =
    /^(?:https?:\/\/)?(?:w{3}\.)?(?:[a-z]{2}\.)?xhamster\.com\/videos\/.*-([0-9]+)$/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  p =
    /^(?:https?:\/\/)?(?:w{3}\.)?(?:[a-z]{2}\.)?xhamster\.com\/embed\/([0-9]+)$/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  return false;
}

export function validRedtube(url) {
  let p = /^(?:https?:\/\/)?(?:w{3}\.)?(?:[a-z]{2}\.)?redtube\.com\/([0-9]+)$/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  p =
    /^(?:https?:\/\/)?(?:embed\.)?(?:[a-z]{2}\.)?redtube\.com\/.*id=([0-9]+)$/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  return false;
}

/* PASSWORD-RELATED */

const containSpecialCharacter = (value) => {
  return value.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
};

const containLowercaseCharacter = (value) => {
  return value.match(/[a-z]/);
};

const containUppercaseCharacter = (value) => {
  return value.match(/[A-Z]/);
};

const containNumber = (value) => {
  return value.match(/[0-9]/);
};

export const calculatePasswordStrength = (password: string) => {
  let strength = 0;
  if (containSpecialCharacter(password)) strength = strength + 2;
  if (containLowercaseCharacter(password)) strength++;
  if (containUppercaseCharacter(password)) strength++;
  if (containNumber(password)) strength++;
  if (password.length > 3) strength++;
  if (password.length > 7) strength++;
  return strength;
};

// TODO fix me in the future to only accept password of specific complexity
export const isValidPassword = (password: string) => {
  return password.length > 6;
};

export const isPornLink = (url) => {
  return (
    validPornhub(url) ||
    validXvideos(url) ||
    validXhamster(url) ||
    validRedtube(url)
  );
}
