import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import NavIcon from './NavIcon/NavIcon';
import Notifications from './Notifications/Notifications';

import { useLogin } from '../../utils/auth.utils';
import { getUrl } from '../../utils/locale/hreflang.utils';
import useLocale from '../../utils/locale/locale.hook';

import ModalSlice from '../../store/slices/modal.slice';
import colors from '../../themes/colors-v2.theme';
import GetTheAppHeader from './get-the-app-header.component';

const MobileSidebar = () => {
  const history = useHistory();
  const locale = useLocale();
  const { isLogin, me } = useLogin();
  const dispatch = useDispatch();

  const notifStore = useSelector((state: any) => state.notifStore);
  const { openedConversation } = useSelector((state: any) => state.chatStore);

  const [active, setActive] = useState(null);
  const [showGetTheApp, setShowGetTheApp] = useState(false);
  const isSubscribed = me?.role === 'premium';

  const [activeTab, setActiveTab] = useState(getUrl().pathname.split('/')[1] || '/');

  useEffect(() => {
    setActiveTab(getUrl().pathname.split('/')[1] || '/');
  }, [history.location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      setShowGetTheApp(true);
    }, 60000);
  }, []);

  const initVisibleActive = () => {
    const url = getUrl();
    const pathname = url.pathname.split('/')[1];
    const navbarActive = pathname as
      | 'feed'
      | 'nearby'
      | 'live'
      | 'rating'
      | 'premium'
      | 'guide'
      | 'notifications'
      | 'favorites'
      | 'classification'
      | 'views'
      | null;
    setActive(navbarActive);
  };

  useEffect(() => {
    history.listen(() => {
      const url = getUrl();
      const pathname = url.pathname.split('/')[1];
      const navbarActive = pathname as
        | 'feed'
        | 'nearby'
        | 'live'
        | 'rating'
        | 'premium'
        | 'guide'
        | 'notifications'
        | 'classification'
        | 'favorites'
        | null;
      setActive(navbarActive);
    });
    initVisibleActive();
  }, []);

  return (
    <Container hidden={history.location.pathname === '/explore' || openedConversation !== null}>
      <TopContainer isSubscribed={Boolean(isSubscribed)}>
        {!isSubscribed ? (
          <SusbcriptionAd onClick={() => dispatch(ModalSlice.actions.setPremium({ visible: true }))}>
            <img src="/assets/icons/logo/bearwwx.svg" />
            <div style={{ fontWeight: 600 }}>{locale('premium.subscribe')}</div>
          </SusbcriptionAd>
        ) : null}
        <CategoriesContainer>
          <NavLink activated={active === 'nearby'} to={'/nearby'}>
            <NavElement activated={activeTab === 'nearby'}>
              <NavIcon icon="nearby" activated={activeTab === 'nearby'} />
              <Label>{locale('global.radar')}</Label>
            </NavElement>
          </NavLink>
          {/* DISCOVER SILENCED AS A REQUEST OF ALAIN */}
          {/* <NavLink to="/discover" activated={false}>
            <NavElement activated={activeTab === 'discover' || history.location.pathname === '/'}>
              <NavIcon icon={'discover'} activated={activeTab === 'discover' || history.location.pathname === '/'} />
              <Label>{locale('global.discover')}</Label>
            </NavElement>
          </NavLink> */}
          <NavLink to="/messages" activated={activeTab === 'messages'}>
            <NavElement activated={activeTab === 'messages'}>
              <NavIcon icon={'message'} activated={activeTab === 'messages'} />
              {notifStore.messagesNotSeen > 0 && (
                <Notifications
                  notificationNumber={notifStore.messagesNotSeen}
                  maxNumberOfChar={1}
                  right={-10}
                  top={-5}
                />
              )}
              <Label>{locale('global.messages')}</Label>
            </NavElement>
          </NavLink>
          <NavLink to="/favorites" activated={activeTab === 'favorites'}>
            <NavElement activated={activeTab === 'favorites'}>
              <NavIcon activated={activeTab === 'favorites'} icon={'star'} />
              <Label>{locale('global.mobile.favorites')}</Label>
            </NavElement>
          </NavLink>

          <NavLink to="/viewers" activated={activeTab === 'viewers'}>
            <NavElement activated={activeTab === 'viewers'}>
              <NavIcon activated={activeTab === 'viewers'} icon={'view'} />
              {notifStore.viewsNotSeen > 0 && (
                <Notifications notificationNumber={notifStore.viewsNotSeen} maxNumberOfChar={1} right={-10} top={-5} />
              )}
              <Label>{locale('global.mobile.viewers')}</Label>
            </NavElement>
          </NavLink>
        </CategoriesContainer>
      </TopContainer>
      {!isLogin && <GetTheAppHeader showGetTheApp={showGetTheApp} />}
    </Container>
  );
};

const Container = styled.nav<{ hidden?: boolean }>`
  position: fixed;
  box-sizing: border-box;
  z-index: 10;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    z-index: 501;
    ${(props) => (props.hidden ? 'display: none;' : '')}
  }
`;

const TopContainer = styled.div<{ isSubscribed?: boolean }>`
  width: 100%;
  border-bottom: 1px solid ${colors.lightGrey7};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    position: fixed;
    border-bottom: 0px;
    bottom: 0px;
    overflow: hidden;
    width: 100%;
    backdrop-filter: blur(20px);
    background-color: ${colors.dark}AA;
    z-index: 10003;
    ${(props) =>
      props.isSubscribed &&
      `
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    `}
  }
`;

const CategoriesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  flex: 1;

  @media (max-width: 1000px) {
    max-width: 100vw;
    width: 90%;
  }
`;

const NavLink = styled(Link)<{ activated }>`
  color: ${(props) => (props.activated ? '#101113' : '#717171')};
  position: relative;
  width: 104px;
  height: 80px;
  border-bottom: 2px solid ${({ activated }) => (activated ? colors.accent : 'transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-transform: capitalize;

  img.active {
    display: ${(props) => (props.activated ? 'inline-block' : 'none')};
  }

  img.notActive {
    display: ${(props) => (props.activated ? 'none' : 'inline-block')};
  }

  @media (max-width: 1000px) {
    height: 60px;
    border: none;
  }
`;

const Label = styled.div`
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;
`;

const NavElement = styled.div<{ activated: boolean }>`
  color: ${(props) => (props.activated ? colors.darkBlue : "#C1C1C1")};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-direction: column;

  &:hover {
    color: ${colors.darkBlue};
  }

  img.active {
    display: ${(props) => (props.activated ? 'inline-block' : 'none')};
  }

  img.notActive {
    display: ${(props) => (props.activated ? 'none' : 'inline-block')};
  }

  @media (max-width: 1000px) {
    flex: 1;
    justify-content: center;
  }
`;

const SusbcriptionAd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  margin: 0 10px;
  padding: 10px 0 0 0;
  width: 90%;
  color: ${colors.white};
  height: 58px;
  box-sizing: border-box;
`;

export default MobileSidebar;
