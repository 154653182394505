import React, { useState, useRef, useEffect, Fragment } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroller from 'react-infinite-scroller';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import useLocale from '../../../../utils/locale/locale.hook';

import Message from './message.component';
import UsersTyping from './users-typing.component';

import ChatSlice from '../../../../store/slices/chat.slice';

import { FancyScrollbar } from '../../../../components/style/fancy-scrollbar.style';
import QuickshareForm from './message-type/quickshare-form';
import { useLogin } from '../../../../utils/auth.utils';
import colors from '../../../../themes/colors-v2.theme';

interface ComponentProps {
  conversation: any;
  messages: any[];
  setConversationMessages: React.Dispatch<React.SetStateAction<any>>;
  getMoreMessages: (page: number) => void;
  hasMoreMessages: boolean;
  usersTyping: any[];
  showQuickshareForm: boolean;
  setShowQuickshareForm: React.Dispatch<React.SetStateAction<boolean>>;
}

let timeout = null;

export default function ConversationMessages({
  conversation,
  messages,
  setConversationMessages,
  getMoreMessages,
  hasMoreMessages,
  usersTyping,
  showQuickshareForm,
  setShowQuickshareForm,
}: ComponentProps) {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const chatStore = useSelector((state: any) => state.chatStore);
  const bottomRef = useRef(null);
  const [randomKey, setRandomKey] = useState(Math.random());
  const { me } = useLogin();

  const locale = useLocale();

  // permet, à chaque changement des messages, de scroller en bas de la conversation
  useEffect(() => {
    if (chatStore.scrollToBottom) {
      if (isDesktop) {
        timeout = setTimeout(() => {
          bottomRef.current.scrollIntoView();
          dispatch(ChatSlice.actions.setScrollToBottom(false));
        }, 200);
      } else {
        // sur mobile, on doit attendre un peu avant de lancer l'action de scroller
        timeout = setTimeout(() => {
          bottomRef.current.scrollIntoView();
          dispatch(ChatSlice.actions.setScrollToBottom(false));
        }, 300);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [chatStore.scrollToBottom, isDesktop, showQuickshareForm]);

  useEffect(() => {
    // reset infinite scroll initial page
    setRandomKey(Math.random());
  }, [conversation?.id]);

  const renderQuickShareConfirmation = () => {
    if (showQuickshareForm) {
      return <QuickshareForm conversation={conversation} setShowQuickshareForm={setShowQuickshareForm} />;
    }
  };

  return (
    <Wrapper key={randomKey}>
      <div style={{ marginTop: 'auto' }}></div>
      <InfiniteScroller
        pageStart={0}
        loadMore={getMoreMessages}
        hasMore={hasMoreMessages}
        useWindow={false}
        isReverse={true}
        initialLoad={false}
      >
        {messages.map((message, index) => {
          const nextMessage = messages[index - 1];
          const shouldDisplayDate =
            index === 0 || (!!nextMessage && moment(nextMessage.createdAt).date() !== moment(message.createdAt).date());
          return (
            <Fragment key={index}>
              {shouldDisplayDate && (
                <DateBadge>
                  {moment(Date.now()).date() === moment(message.createdAt).date()
                    ? locale('global.today')
                    : moment(message.createdAt).format('L')}
                </DateBadge>
              )}
              {index > 0 && message.sender && message.sender.id !== messages[index - 1].sender.id ? (
                <Separator />
              ) : null}
              <Message
                message={message}
                sender={message.sender}
                conversation={conversation}
                setConversationMessages={setConversationMessages}
              />
              {message.error && (
                <div style={{ color: 'red', fontSize: 12, marginTop: 8, textAlign: 'right', paddingBottom: 8, paddingRight: 12 }}>
                  {message.error}
                </div>
              )}
            </Fragment>
          );
        })}
        {renderQuickShareConfirmation()}
      </InfiniteScroller>
      {me.role === 'premium' ? <UsersTyping users={usersTyping} /> : null}
      <div style={{ float: 'left', clear: 'both' }} ref={bottomRef}></div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-top: auto;

  display: flex;
  flex-direction: column;
  background: ${colors.darkGradient};

  ${FancyScrollbar}
`;

const DateBadge = styled.div`
  width: min-content;
  margin: 18px auto;
  padding: 4px 16px;
  border-radius: 14px;
  font-size: 11px;
  font-weight: 400;
  color: ${colors.darkText};
`;

const Separator = styled.div`
  width: 100%;
  height: 8px;
`;
