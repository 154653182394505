import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Switch, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import ScreenWrapper from '../../components/style/screen-wrapper.component';
import GuidesDisplayer, { ActivityDisplay, GuideType } from './components/guides-displayer.component';
import GuideDisplayer from './components/details/guide-displayer.component';
import SubmitGuideModal from './modals/submit-guide.modal';
import DeleteGuideModal from './modals/delete-guide.modal';
import { GuidesCategories } from './components/guides-categories.component';
import GuidesHeader from './components/guides-header.component';
import { useLogin } from '../../utils/auth.utils';

export default function GuideScreen(props) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [guideToDelete, setGuideToDelete] = useState(null);
  const [guideToUpdate, setGuideToUpdate] = useState(null);
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  const [mapOpen, setMapOpen] = React.useState(false);
  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [weekOnly, setWeekOnly] = useState(false);
  const [type, setType] = useState<GuideType>(null);
  const [activityDisplay, setActivityDisplay] = useState(ActivityDisplay.ALL);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [isGuidesCategoriesOpen, setIsGuidesCategoriesOpen] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<string>('');
  const { isLogin } = useLogin();

  return (
    <ScreenWrapper>
      <Wrapper isDesktop={isDesktop}>
        <Switch>
          <Route
            path="/discover/guide/:guideSlug/details"
            render={() => (
              <GuideDisplayer
                openSubmitEventModal={() => setShowSubmitModal(true)}
                openDeleteGuideModal={(guide: any) => {
                  setGuideToDelete(guide);
                  setShowDeleteModal(true);
                }}
                openUpdateGuideModal={(guide: any) => {
                  setGuideToUpdate(guide);
                  setShowSubmitModal(true);
                }}
                shouldRefreshData={shouldRefreshData}
                setShouldRefreshData={setShouldRefreshData}
              />
            )}
          />
          <Route
            path="/discover/guide/:slug"
            render={() => (
              <>
                {isLogin && (
                  <GuidesHeader
                    type={type}
                    setThisWeek={setWeekOnly}
                    weekOnly={weekOnly}
                    activityToDisplay={activityDisplay}
                    setActivityToDisplay={setActivityDisplay}
                    setIsSearchOpen={setIsSearchOpen}
                    activeCategory={activeCategory}
                    setActiveCategory={setActiveCategory}
                  />
                )}
                <GuidesContainer>
                  {
                    <GuidesCategories
                      openSubmitEventModal={() => setShowSubmitModal(true)}
                      mapOpen={mapOpen}
                      setMapOpen={setMapOpen}
                      coords={coords}
                      setCoords={setCoords}
                      links={[
                        'guide_events',
                        'guide_bars',
                        'guide_restaurants',
                        'guide_shopping',
                        'guide_associations',
                        'guide_accommodations',
                        'guide_art_culture',
                      ]}
                    />
                  }
                  <GuidesDisplayer
                    openSubmitEventModal={() => setShowSubmitModal(true)}
                    setShowSubmitModal={setShowSubmitModal}
                    mapOpen={mapOpen}
                    setMapOpen={setMapOpen}
                    coords={coords}
                    setCoords={setCoords}
                    weekOnly={weekOnly}
                    setWeekOnly={setWeekOnly}
                    type={type}
                    setType={setType}
                    activityDisplay={activityDisplay}
                    setActivityDisplay={setActivityDisplay}
                    setThisWeek={setWeekOnly}
                    isSearchOpen={isSearchOpen}
                    setIsSearchOpen={setIsSearchOpen}
                    setIsGuidesCategoriesOpen={setIsGuidesCategoriesOpen}
                    activeCategory={activeCategory}
                  />
                </GuidesContainer>
              </>
            )}
          />
        </Switch>
        <SubmitGuideModal
          visible={showSubmitModal}
          closeModal={() => {
            setShowSubmitModal(false);
            clearAllBodyScrollLocks();
            setGuideToUpdate(null);
          }}
          guideToUpdate={guideToUpdate}
          refreshDataHandler={() => setShouldRefreshData(true)}
        />
        <DeleteGuideModal
          visible={showDeleteModal}
          closeModal={() => {
            setShowDeleteModal(false);
            setGuideToDelete(null);
          }}
          guide={guideToDelete}
        />
      </Wrapper>
    </ScreenWrapper>
  );
}

const Wrapper = styled.div<{ isDesktop?: boolean }>`
  padding: 0;
  width: ${(props) => (props.isDesktop ? 'calc(100vw - 260px)' : '')};
  box-sizing: border-box;
  z-index: 10;
  background: transparent;
`;
const GuidesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
`;
