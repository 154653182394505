import styled from 'styled-components/macro';
import colors from '../../../themes/colors-v2.theme';
import fonts from '../../../themes/fonts.theme';

export const Container = styled.div<{ isPremium?: boolean }>`
  max-width: 798px;
  width: 798px;
  padding: 28px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 1000px) {
    width: 100%;
    padding-top: 16px;
    margin-bottom: ${(props) => (props.isPremium ? '60px' : '100px')};
  }
`;

export const Section = styled.div`
  background-color: #00000080;
  border-radius: 16px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${colors.graySeparator2};

  @media (max-width: 1000px) {
    border-radius: 0;
    padding: 12px;
  }
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
  font-family: ${fonts.regular.name};
  font-weight: ${fonts.regular.weight};
  color: #ff006d;
`;
