import React from 'react';
import styled from 'styled-components/macro';
import useLocale from '../../../../utils/locale/locale.hook';
import { useSelector } from 'react-redux';
import { updateUser } from '../../../../services/users.service';
import { getCommunities } from '../../../../services/caracteristics.service';
import { Values } from '../../../../components/settings/values.component';
import { Tags } from '../../../../components/settings/tags.component';
import colors from '../../../../themes/colors-v2.theme';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '../../../../components/forms/primaryButton.component';

type CommunityProps = {
  me?: any;
  onChange?: (community) => void;
};

const Community = (props: CommunityProps) => {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [communities, setCommunities] = React.useState(null);
  const [community, setCommunity] = React.useState(props?.me?.community?.communityKey);
  const keys = useSelector((state: any) => state.localeStore.keys);

  const getData = async () => {
    const res = await getCommunities();
    setCommunities(res);
  };

  const onSelect = async (selectedCommunity) => {
    if (selectedCommunity === community) {
      selectedCommunity = null;
    }
    setCommunity(selectedCommunity);
    await updateUser({
      community: {
        communityKey: selectedCommunity,
      },
    });
    props?.onChange({
      communityKey: selectedCommunity,
    });
  };

  React.useEffect(() => {
    if (keys) {
      getData();
    }
  }, [keys]);

  return (
    <>
      <Title>{locale('edit_profil.i_am.title')}</Title>
      <Values
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tags>
          {communities?.length > 0 &&
            communities.map((communityToSelect, idx) => (
              <PrimaryButton
                key={idx}
                onClick={() => onSelect(communityToSelect.communityKey)}
                isActive={communityToSelect.communityKey === community}
              >
                {locale('community.name.' + communityToSelect.communityKey)}
              </PrimaryButton>
            ))}
        </Tags>
      </Values>
    </>
  );
};

export default Community;

const Title = styled.div`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 700;
`;
