import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Text from "../../../components/style/text.component";

import { toggleBlock, getUsersBlocked } from "../../../services/users.service";

import fonts from "../../../themes/fonts.theme";
import colors from "../../../themes/colors-v2.theme";
import { FancyScrollbar } from "../../../components/style/fancy-scrollbar.style";
import useLocale from "../../../utils/locale/locale.hook";
import User from "../../../components/miscellaneous/user.component";
import { useMediaQuery } from "react-responsive";
import { PrimaryButton } from "../../../components/forms/primaryButton.component";

enum UnblockStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
}

export default function UnblockGestion() {
  const locale = useLocale();
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const [error, setError] = useState<string>("");
  const [usersBlocked, setUsersBlocked] = useState<any[]>(null);
  const [unblockStatus, setUnblockStatus] = useState(UnblockStatus.IDLE);

  const unblockUserHandler = async (userId: string) => {
    if (unblockStatus === UnblockStatus.LOADING) {
      return;
    }

    setUnblockStatus(UnblockStatus.LOADING);
    const res = await toggleBlock(userId);
    if (typeof res?.blocked !== "undefined") {
      setUnblockStatus(UnblockStatus.IDLE);
      getUsersBlocked().then((res) => {
        if (Array.isArray(res)) {
          setUsersBlocked(res);
        }
      });
    } else {
      setUnblockStatus(UnblockStatus.ERROR);
      setError(res?.error ?? "report.errors.error_occured");
    }
  };

  useEffect(() => {
    getUsersBlocked().then((res) => {
      if (Array.isArray(res)) {
        setUsersBlocked(res);
      }
    });
  }, []);

  return (
    <SectionWrapper>
      {unblockStatus === UnblockStatus.ERROR && <Text>{locale(error)}</Text>}
      {usersBlocked && (
        <BlockedWrapper>
          {usersBlocked.map((user) => (
            <UserBlocked key={user.id}>
              <User
                user={user}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  aspectRatio: "1/1",
                  marginBottom: 12,
                  overflow: "hidden",
                }}
                isUnblockGrid
              />
              <PrimaryButton
                size="medium"
                style={{
                  padding: "5px 25px",
                  marginBottom: 8,
                  borderRadius: 8,
                  background: colors.dark,
                }}
                onClick={() => unblockUserHandler(user.id)}
              >
                {locale("unblock.general")}
              </PrimaryButton>
            </UserBlocked>
          ))}
        </BlockedWrapper>
      )}
    </SectionWrapper>
  );
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1217px;
  box-sizing: border-box;
  margin: 0 auto 50px auto;
  border-radius: 4px;

  @media (max-width: 1000px) {
    padding: 0 4px;
  }
`;

const BlockedWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  overflow-y: auto;
  gap: 12px;
  padding-right: 4px;
  padding-left: 4px;

  ${FancyScrollbar}
`;

const UserBlocked = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;

  & > .profilePicture {
  }

  & > .pseudo {
    margin: 4px 0;
    font-size: 14px;
    font-family: ${fonts.semiBold.name};
    font-weight: ${fonts.semiBold.weight};
    color: ${colors.darkerGrey};
  }
`;
