import { del, put, post, get } from './utils.service';
import * as queryString from 'query-string';

export const getMyTravels = async (query) => {
  const cleanedQuery = Object.keys(query).reduce((rslt, key) => {
    if (query[key] || !['latitude', 'longitude'].includes(key)) {
      rslt[key] = ['latitude', 'longitude'].includes(key) ? parseFloat(query[key]) : query[key];
    }
    return rslt;
  }, {});

  const res = await get(`/travel?${queryString.stringify(cleanedQuery)}`);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const addATravel = async (travel) => {
  const res = await post(`/travel`, travel);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const removeATravel = async (travelUID) => {
  const res = await del(`/travel`, travelUID);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};

export const updateATravel = async (newTravel) => {
  const res = await put(`/travel`, newTravel);
  const payload = await res.json();
  return payload.status === 'success' ? payload.data : { error: payload.message };
};
