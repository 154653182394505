import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { NotifProvider } from "./context/notif-context";
import Cookies from "universal-cookie";
import { environment } from "./environments";

// // Sentry Config of Frontend
// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     dsn: "https://a197854b903acc12f15bec7804980cf0@o4508007013875712.ingest.de.sentry.io/4508059127316560",
//     integrations: [
//       Sentry.browserProfilingIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
  
//     // Set sampling rate for profiling - this is relative to tracesSampleRate
//     profilesSampleRate: 1.0,
//   });
// }

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");
const showModal = urlParams.get("showModal");
if (token && showModal !== "resetPassword") {
  const cookies = new Cookies();
  cookies.set("token", token, {
    domain: environment.cookieDomain,
    path: "/",
    secure: true,
  });
  localStorage.setItem("token", token);
  const language = urlParams.get("language");
  if (language) {
    localStorage.setItem("language", language);
  }

  window.location.href = window.location.origin;
}

ReactDOM.render(
  <React.StrictMode>
    <NotifProvider>
      <App />
    </NotifProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
