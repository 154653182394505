import React, { MutableRefObject, RefCallback, forwardRef, useRef } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/forms/primaryButton.component';
import { Flex } from '../../../components/style/flex.component';
import { useOnClickOutside } from '../../../utils/clickOutside.utils';

type Props = {
  isOpen: boolean;
  onClose: (e?: any) => void;
  style?: React.CSSProperties;
  title?: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
  children?: React.ReactNode;
  childrenStyle?: React.CSSProperties;
};

type MutableRefList<T> = Array<RefCallback<T> | MutableRefObject<T> | undefined | null>;

function mergeRefs<T>(...refs: MutableRefList<T>): RefCallback<T> {
  return (val: T) => {
    setRef(val, ...refs);
  };
}

function setRef<T>(val: T, ...refs: MutableRefList<T>): void {
  refs.forEach((ref) => {
    if (typeof ref === 'function') {
      ref(val);
    } else if (ref != null) {
      ref.current = val;
    }
  });
}

// eslint-disable-next-line react/display-name
export const UserModalTemplate = forwardRef(
  ({ isOpen, onClose, children, title, style, childrenStyle, ref: forwardedRef }: Props) => {
    const modalWrapperRef = useRef<HTMLDivElement>(null);
    useOnClickOutside([modalWrapperRef], () => {
      onClose();
    });
    const modalStyles: React.CSSProperties = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      alignItems: 'center',
      display: isOpen ? 'block' : 'none',
      zIndex: 10,
      borderRadius: 8,
      boxSizing: 'border-box',
    };

    return (
      <div style={{ ...modalStyles, ...style }} ref={mergeRefs(modalWrapperRef, forwardedRef)}>
        {title ? (
          <Flex justify="space-between" style={{ marginBottom: 16 }}>
            {title}
            <Cross onClick={onClose}>
              <PrimaryButton size="medium" style={{ borderColor: 'white', width: 36, height: 36 }}>
                <img alt="cross" src="/assets/icons/cross-outlined-white.svg" width={16} height={16} />
              </PrimaryButton>
            </Cross>
          </Flex>
        ) : (
          <Cross onClick={onClose}>
            <PrimaryButton size="medium" style={{ borderColor: 'white', width: 36, height: 36 }}>
              <img alt="cross" src="/assets/icons/cross-outlined-white.svg" width={16} height={16} />
            </PrimaryButton>
          </Cross>
        )}
        <Children style={childrenStyle}>{children}</Children>
      </div>
    );
  }
);

const Cross = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 11;
`;

const Children = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
`;
