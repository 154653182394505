import React, { createContext, useReducer, useContext } from 'react';
import { createPortal } from 'react-dom';
import Notif from "../components/notif-component";

interface ContextProps {
  notif: Array<any>;
  notifDispatch: ({type, payload}:{type:string, payload:any}) => void;
}

const NotifContext = createContext({} as ContextProps);

const initialState = [];

export const ADD = 'ADD';
export const REMOVE = 'REMOVE';
export const REMOVE_ALL = 'REMOVE_ALL';

const notifReducer = (state, action) => {
  switch(action.type) {
    case ADD: 
      return [
        ...state,
        {
          id: +new Date(),
          content: action.payload.content
        }
      ];
    case REMOVE: 
      return state.filter(t => t.id !== action.payload.id);
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

export const NotifProvider = props => {
  const [notif, notifDispatch] = useReducer(notifReducer, initialState);
  const notifData = {notif, notifDispatch};
  return (
    <NotifContext.Provider value={notifData}>
      {props.children}

      {createPortal(<Notif notif={notif} />, document.body)}
      
    </NotifContext.Provider>
  );
}

export const useNotifContext = () => {
  return useContext(NotifContext);
}
