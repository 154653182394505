import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { BoostModal } from "../../components/boost-modal";
import { PrimaryButton } from "../../components/forms/primaryButton.component";
import IosSwitch from "../../components/ios-switch/ios-switch.component";
import { Flex } from "../../components/style/flex.component";
import Icons from "../../components/style/icons.component";
import Text from "../../components/style/text.component";
import { updateUser } from "../../services/users.service";
import MeSlice from "../../store/slices/me.slice";
import ModalSlice from "../../store/slices/modal.slice";
import colors from "../../themes/colors-v2.theme";
import { useLogin } from "../../utils/auth.utils";
import useLocale from "../../utils/locale/locale.hook";
import { useIsPremium } from "../../utils/useUser.utils";
import { ShoutoutModal } from "../messages/components/shoutout-modal.component";

const ProfileMobile = () => {
  const history = useHistory();
  const locale = useLocale();
  const { me } = useLogin();
  const dispatch = useDispatch();
  const [isBoostOpen, setIsBoostOpen] = useState(false);
  const [isShoutoutOpen, setIsShoutoutOpen] = useState(false);
  const isPremium = useIsPremium();

  const handleSwitchChange = async (isOn: boolean) => {
    const status = isOn ? "incognito" : "online";
    const res = await updateUser({ status: status });
    if (res.success === true) {
      dispatch(MeSlice.actions.updateMe({ status: status }));
    } else if (res.error === "premium.incognitoMode.error") {
      dispatch(ModalSlice.actions.setPremium({ visible: true }));
    } else {
      // DEBUG: server error to be handled
    }
  };

  return (
    <div>
      <Wrapper isPremium={isPremium}>
        <SettingsHeader style={{ alignSelf: "flex-start" }}>
          <div
            style={{ transform: "rotate(180deg)" }}
            onClick={() => history.push("/nearby")}
          >
            <img
              src="/assets/icons/nearby/chevron-right-white.svg"
              alt="chevron-left"
              width={12}
              height={12}
            />
          </div>
          <div>{locale("account.title")}</div>
        </SettingsHeader>
        <SettingsWrapper>
          <Flex direction="column" alignItems="center" gap="8px">
            <ProfilePicture
              alt=""
              src={me?.profilePicture?.path}
              width="112"
              height="112"
            />
            <Text>{me.pseudo}</Text>
            <PrimaryButton
              onClick={() => {
                history.push("/settings/edit-profile");
              }}
              style={{ height: 38 }}
            >
              <img
                src="/assets/icons/sidebar/navbar/user.svg"
                alt="edit"
                width={16}
                height={16}
              />
              {locale("account.edit_my_profile")}
            </PrimaryButton>
          </Flex>

          <div style={{ marginTop: 16, paddingLeft: 8, paddingRight: 8 }}>
            <CardsContainer>
              <Card
                onClick={() => {
                  history.push("/settings/general");
                }}
              >
                <img
                  src="/assets/icons/user-profile/settings.svg"
                  alt="edit"
                  width={24}
                  height={24}
                />

                <Text fontSize="12px" bold>
                  {locale("account.settings.title")}
                </Text>
              </Card>
              <Card
                onClick={() =>
                  history.push({
                    pathname: "/trips",
                    state: { tab: "myTrips" },
                  })
                }
              >
                <img
                  src="/assets/icons/sidebar/navbar/calendar-white.svg"
                  width={24}
                  height={24}
                />

                <Text fontSize="12px" bold>
                  {locale("travel.mytrips")}
                </Text>
              </Card>
              <Card>
                <IosSwitch
                  isOn={me.status === "incognito"}
                  onChange={handleSwitchChange}
                />
                <Flex alignItems="center" gap="2px">
                  <Icons
                    name="status-incognito-dark"
                    width="16"
                    height="16"
                    style={{
                      marginRight: 7,
                      marginLeft: 7,
                      padding: "5px 0px 3px 0px",
                    }}
                  />
                  <Text fontSize="12px" bold>
                    {locale("account.status.incognito")}
                  </Text>
                  <img
                    src="/assets/icons/user-profile/premiumX.svg"
                    width={13}
                    height={16}
                  />
                </Flex>
              </Card>
            </CardsContainer>
            {["admin", "premium"].includes(me.role) ? null : (
              <PremiumWrapper
                onClick={() => {
                  dispatch(ModalSlice.actions.setPremium({ visible: true }));
                }}
              >
                <PremiumContainer>
                  <PremiumTitle>
                    <img
                      src="/assets/icons/logo/bearwwx.svg"
                      width={72}
                      height={18}
                    />
                  </PremiumTitle>
                  <Flex direction="column">
                    <PremiumSubtitle>
                      {locale("global.premium.description")}
                    </PremiumSubtitle>
                    <PremiumButton>
                      <Text
                        style={{
                          boxShadow: "0px 1px 2px 0px #A36715",
                          fontSize: "12px",
                        }}
                      ></Text>
                      {locale("global.premium.subscribe")}
                    </PremiumButton>
                  </Flex>
                </PremiumContainer>
              </PremiumWrapper>
            )}
          </div>
        </SettingsWrapper>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div<{ isPremium: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin-bottom: ${(props) => (props.isPremium ? "30px" : "120px")};
`;

const SettingsHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  font-size: 16px;
  font-weight: 600;
`;

const SettingsWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
  background: #16191e4d;
  box-sizing: border-box;
`;

const Card = styled.div`
  width: 169px;
  height: 86px;
  border-radius: 8px;
  border: 1px solid ${colors.white};
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
`;

const ProfilePicture = styled.img`
  border-radius: 16px;
  object-fit: cover;
  cursor: pointer;
`;

const PremiumWrapper = styled.div`
  cursor: pointer;
  margin-top: 8px;
  height: 132px;
  display: flex;
  background: linear-gradient(
    to right,
    #d93427,
    #f69101,
    #dfd902,
    #14aa5f,
    #2d56c5,
    #4c2e7b
  );
  border-radius: 8px;
  padding: 2px;
  overflow: hidden;
`;

const ShoutoutContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.black};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  border: 2px solid #6e3cf7;
  background-image: url("/assets/icons/user-profile/shoutout-background.svg");
  background-position: 50% 50%;
  box-sizing: border-box;
`;

const PremiumContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-image: url("/assets/icons/sidebar/navbar/premium-background.png");
  background-position: center;
  background-size: 180%;
  border-radius: 8px;
  padding: 0 16px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    z-index: 1;
  }
`;

const PremiumTitle = styled.div`
  color: white;
  font-weight: bold;
  text-align: center;
  z-index: 2;
  margin: 0;
`;

const PremiumSubtitle = styled.p`
  font-size: 14px;
  color: white;
  text-align: center;
  font-weight: 500;
  margin: 0;
  z-index: 2;
`;

const PremiumButton = styled.button`
  color: white;
  background: linear-gradient(209.2deg, #fdb022 9.53%, #ff7a00 111.75%);
  padding: 8px 14px 8px 14px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  z-index: 2;
`;

const BoostContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.black};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  border: 2px solid #00ca99;
  background-image: url("/assets/icons/user-profile/boost-background.svg");
  background-position: 50% 50%;
  box-sizing: border-box;
`;

const BoostIcon = styled.img`
  border-radius: 4px;
  object-fit: cover;
`;

// const BoostModalContainer = styled.div`
//   width: 100%;
//   padding: 32px 72px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 21px;
//   border-radius: 16px;
//   box-sizing: border-box;
//   @media (max-width: 1000px) {
//     padding: 32px 0;
//   }
// `;

// const BoostProfilePicturesWrapper = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-auto-rows: 118px;
//   gap: 13px;
// `;

export default ProfileMobile;
