import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import ModalSlice from "../../../store/slices/modal.slice";
import useLocale from "../../../utils/locale/locale.hook";

import { useMediaQuery } from "react-responsive";
import Webcam from "react-webcam";
import Text from "../../../components/style/text.component";
import { sendLocation } from "../../../services/chat.service";
import colors from "../../../themes/colors-v2.theme";
import { useLogin } from "../../../utils/auth.utils";
import { getLocation } from "../../../utils/geolocation.utils";
import QuickshareMenu from "../../user-profile/components/action-menus/quickshare-menu.component";
import { UserModalTemplate } from "../../user-profile/components/user-modal.component";
import { AddMediaStatus } from "../types/add-media-status.enum";
import { ModalsShown } from "../types/modals-shown.type";
import { WebcamStreamCapture } from "./capture-video.component";

interface ComponentProps {
  conversation: any;
  addMediaStatus: AddMediaStatus;
  setAddMediaStatus: React.Dispatch<React.SetStateAction<AddMediaStatus>>;
  setModals: React.Dispatch<React.SetStateAction<ModalsShown>>;
  setShowQuickshareForm: React.Dispatch<React.SetStateAction<boolean>>;
  sendMessageHandler: () => void;
  messageContent: string;
  setMessageContent: React.Dispatch<React.SetStateAction<string>>;
  sendPicturesMessageHandler: (
    pictures: Array<{ path: string; isVideo?: boolean }>
  ) => void;
  sendEphemeralMessageHandler: (pictures: any[]) => void;
  onSendLocation: ({
    conversationId,
    latitude,
    longitude,
  }: {
    conversationId: string;
    latitude: string;
    longitude: string;
  }) => void;
}

type Actions =
  | "picture"
  | "location"
  | "gif"
  | "ephemeral"
  | "video"
  | "quickshare"
  | "album";

enum SendStatus {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
}

enum ModalMode {
  OPTIONS = "OPTIONS", // on affiche les 2 options possibles
  CAMERA = "CAMERA", // on affiche la caméra
  LOADING = "LOADING", // on affiche un loader (pendant l'upload des photos)
}

export default function ConversationActions({
  conversation,
  setAddMediaStatus,
  setModals,
  setShowQuickshareForm,
  sendMessageHandler,
  messageContent,
  setMessageContent,
  sendPicturesMessageHandler,
  sendEphemeralMessageHandler,
  onSendLocation,
}: ComponentProps) {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { me } = useLogin();
  const webcamRef = useRef<Webcam>(null);

  const [sendStatus, setSendStatus] = useState(SendStatus.IDLE);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(ModalMode.OPTIONS);
  const { selectPictures } = useSelector((state: any) => state.modalStore);
  const [isShareAlbumOpen, setIsShareAlbumOpen] = useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });

  const onKeyDownMessageHandler = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      sendMessageHandler();
    }
  };

  const handleAddMediaOption = (option: Actions) => {
    if (option == "picture") {
      dispatch(
        ModalSlice.actions.setSelectPictures({
          visible: true,
          type: "upload",
          onSelectedPictures: sendPicturesMessageHandler,
          onSelectedAlbum: sendPicturesMessageHandler,
        })
      );
    } else if (option == "location") {
      setModals((prevState: any) => ({
        ...prevState,
        showLocation: true,
      }));
    } else if (option == "ephemeral") {
      if (me.role === "premium" || me.role === "admin") {
        dispatch(
          ModalSlice.actions.setSelectPictures({
            visible: true,
            type: "upload",
            onSelectedPictures: sendEphemeralMessageHandler,
            onSelectedAlbum: sendEphemeralMessageHandler,
          })
        );
      } else {
        dispatch(ModalSlice.actions.setPremium({ visible: true }));
      }
    } else if (option == "quickshare") {
      setShowQuickshareForm(true);
    } else if (option == "album") {
      dispatch(
        ModalSlice.actions.setSelectUserPictures({
          visible: true,
          onPicturesConfirm: sendPicturesMessageHandler,
        })
      );
    }
    setAddMediaStatus(AddMediaStatus.HIDDEN);
  };

  const sendLocationHandler = () => {
    setIsLoading(true);
    if (sendStatus === SendStatus.LOADING) {
      setIsLoading(false);
      return;
    }

    setErrorMessage("");
    if (!me.locationOptin) {
      setErrorMessage(locale("chat.location.permission_error"));
      setSendStatus(SendStatus.ERROR);
      setIsLoading(false);
      return;
    }

    getLocation((data) => {
      if (!data.latitude || !data.longitude) {
        setSendStatus(SendStatus.ERROR);
        setErrorMessage(locale("chat.location.permission_error"));
        setIsLoading(false);
        return;
      }
      setSendStatus(SendStatus.LOADING);
      sendLocation({
        conversationId: conversation.id,
        latitude: data.latitude,
        longitude: data.longitude,
      }).then((res) => {
        if (res?.success) {
          setAddMediaStatus(AddMediaStatus.HIDDEN);
          setIsLoading(false);
          setSendStatus(SendStatus.IDLE);
        } else {
          setIsLoading(false);
          setErrorMessage(locale("parseErr"));
          setSendStatus(SendStatus.ERROR);
        }
      });
      onSendLocation({
        conversationId: conversation.id,
        latitude: data.latitude,
        longitude: data.longitude,
      });
    });
  };

  const closeModalHandler = () => {
    setMode(ModalMode.OPTIONS);
  };

  return (
    <ActionsWrapper>
      {errorMessage && (
        <ErrorModalOverlay>
          <ErrorModal onClick={() => setErrorMessage("")}>
            <CloseErrorModal
              src="/assets/icons/cross-outlined-white.svg"
              alt="Close modal button"
            />
            <Text>{errorMessage}</Text>
          </ErrorModal>
        </ErrorModalOverlay>
      )}
      <UserModalTemplate
        isOpen={mode === ModalMode.CAMERA}
        onClose={closeModalHandler}
        style={{
          width: "95%",
          borderRadius: 16,
          height: "fit-content",
          maxHeight: "fit-content",
          background: colors.darkGray,
          position: "absolute",
          bottom: isDesktop ? 140 : 60,
          border: `1px solid ${colors.grayBorder}`,
          padding: "14px 24px",
          boxSizing: "border-box",
        }}
        childrenStyle={{
          alignItems: "center",
        }}
      >
        <ModalHeader>
          <div
            style={{
              border: `1px solid ${colors.grayBorder}`,
              borderRadius: 100,
              width: 56,
              height: 56,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/icons/messages/icons-actions-video-white.svg"
              alt="image icon"
              width={24}
              height={24}
            />
          </div>
          <Text color="white" bold fontSize="16px">
            {locale("modal.select_camera.label")}
          </Text>
        </ModalHeader>
        {mode == ModalMode.CAMERA && (
          <WebcamStreamCapture
            onSelectedPictures={sendPicturesMessageHandler}
            onClose={closeModalHandler}
          />
        )}
      </UserModalTemplate>
      {isShareAlbumOpen && conversation?.conversation_users[0]?.user && (
        <QuickshareMenu
          user={conversation.conversation_users[0].user}
          onClose={() => setIsShareAlbumOpen(false)}
        />
      )}

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          gap: "54px",
          marginTop: "12px",
        }}
      >
        {MediaRecorder.isTypeSupported("video/mp4") &&
          <AddMediaOption
            onClick={() => {
              setMode(ModalMode.CAMERA);
            }}
          >
            <img
              alt="icon action"
              src="/assets/icons/messages/icons-actions-video-white.svg"
              width={24}
              height={24}
            />
          </AddMediaOption>
        }
        <AddMediaOption onClick={() => handleAddMediaOption("picture")}>
          <img
            alt=""
            src="/assets/icons/messages/preview/picture-inactive.svg"
            width={24}
            height={24}
          />
        </AddMediaOption>
        <AddMediaOption onClick={sendLocationHandler}>
          <img
            alt=""
            src="/assets/icons/messages/icons-actions-localisation.svg"
            width={24}
            height={24}
          />
        </AddMediaOption>
        <AddMediaOption
          onClick={() => {
            handleAddMediaOption("ephemeral");
          }}
        >
          <img
            alt=""
            src="/assets/icons/messages/icons-actions-ephemeral.svg"
            width={24}
            height={24}
          />
        </AddMediaOption>
        <AddMediaOption onClick={() => setIsShareAlbumOpen(true)}>
          <img
            alt=""
            src="/assets/icons/sidebar/navbar/lock-album-white.svg"
            width={24}
            height={24}
          />
        </AddMediaOption>
      </div>
      <div
        style={{
          marginTop: 16,
          marginBottom: 28,
          width: "90%",
        }}
      >
        <MessageInput>
          <input
            type="text"
            placeholder={locale("chat.input.placeholder")}
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
            onKeyDown={onKeyDownMessageHandler}
          />
          <img
            onClick={sendMessageHandler}
            src="/assets/icons/messages/send.svg"
            alt="send"
          />
        </MessageInput>
      </div>
    </ActionsWrapper>
  );
}

const CameraWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${colors.darkerGrey};
  margin-bottom: 16px;
`;

const ActionsWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  background-color: ${colors.dark};

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const MessageInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.grayBorder};
  background: ${colors.darkGray};
  border-radius: 50px;
  padding: 0 8px;
  padding-right: 14px;
  box-sizing: border-box;
  height: 40px;
  min-height: 40px;
  min-width: 185px;

  & > input {
    width: 100%;
    height: calc(100% - 4px);
    border-radius: 50px;
    box-sizing: border-box;
    padding: 0 10px;
    background-color: inherit;
    font-family: inherit;
    font-size: 14px;
    color: ${colors.white};
    outline: none;
    border: none;
  }
`;

const AddMediaOption = styled.label`
  cursor: pointer;
  height: 24px;
`;

const ErrorModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

const ErrorModal = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background-color: ${colors.darkGray};
  padding: 16px;
  width: 300px;
`;

const CloseErrorModal = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 14px;
  height: 14px;
  padding: 4px;
  border: 1px solid ${colors.white};
  border-radius: 100%;
`;
