import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { sendQuickshare } from '../../../../../services/chat.service';
import fonts from '../../../../../themes/fonts.theme';
import useLocale from '../../../../../utils/locale/locale.hook';
import Button from '../../../../../components/forms/button.component';
import colors from '../../../../../themes/colors-v2.theme';
import { useLogin } from '../../../../../utils/auth.utils';
import { getConversationInfo } from '../../../../../utils/chat.utils';
import Icons from '../../../../../components/style/icons.component';
import Text from '../../../../../components/style/text.component';
import { replace } from '../../../../../utils/locale/locale.utils';

export interface Props {
  conversation: any;
  setShowQuickshareForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuickshareForm: React.FC<Props> = ({ conversation, setShowQuickshareForm }) => {
  const { me } = useLogin();
  const { conversationName } = getConversationInfo(me, conversation);
  const [error, setError] = useState(null);

  const locale = useLocale();

  const handleButtonPress = (choice: 'no' | 'yes') => {
    if (choice === 'yes') {
      sendQuickshare({
        conversationId: conversation.id,
        status: 'accepted',
      }).then((res) => {
        if (res?.success) {
          setError(null);
          sendQuickshare({
            conversationId: conversation.id,
            status: 'asked',
          }).then((res) => {
            if (res?.success) {
              setError(null);
              setShowQuickshareForm(false);
            } else {
              setError(res.error);
            }
          });
        } else {
          setError(res.error);
        }
      });
    } else {
      setShowQuickshareForm(false);
    }
  };

  return (
    <Message>
      <TitleContainer>
        <img alt="" width="16px" height="16px" src="/assets/icons/sidebar/navbar/lock-album-white.svg" />
        <Title>{locale('chat.actions.share_album')}</Title>
      </TitleContainer>
      <Text
        dangerouslySetInnerHTML={{
          __html: replace(locale('chat.quickshare.confirmation.me'), {
            pseudo: conversationName,
          }),
        }}
      />
      {error ? (
        <ErrorMessage>
          <Icons name="error-filled-white" width="16" height="16" />
          {locale(error)}
        </ErrorMessage>
      ) : (
        <ButtonContainer>
          <Button
            text={locale('global.no')?.toUpperCase()}
            onClick={() => handleButtonPress('no')}
            style={buttonStyle}
          />
          <Button
            text={locale('global.yes')?.toUpperCase()}
            onClick={() => handleButtonPress('yes')}
            style={buttonStyle}
          />
        </ButtonContainer>
      )}
    </Message>
  );
};

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 4px;
  border-radius: 4px;
  min-width: 80px;
  max-width: 280px;
  background-color: #106bf280;
  float: right;
  margin-right: 12px;

  font-size: 14px;
  color: ${colors.white};

  & > p {
    margin: 0;
  }

  & > .timer {
    margin-right: 8px;
    position: relative;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  margin-bottom: 14px;
  margin-top: 8px;

  & > img {
    margin-right: 8px;
  }

  body {
    display: grid;
    height: 100vh;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 14px 0 8px;
  width: 100%;
`;

const Title = styled.div`
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  font-size: 12px;
`;

const ErrorMessage = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  font-size: 12px;
  margin: 14px 0 8px !important;

  > img {
    margin-right: 8px;
  }
`;

const buttonStyle = {
  display: 'flex',
  flex: 1,
  height: '27px',
  padding: '6px 8px',
  borderRadius: '4px',
  border: 'solid 1px white',
  backgroundColor: 'white',
  color: colors.darkGrey2,
  margin: '0 4px',
};

export default QuickshareForm;
