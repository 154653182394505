import React from "react";
import styled from "styled-components/macro";
import fonts from "../../../../themes/fonts.theme";
import { CircularProgress } from "@material-ui/core";

const CountdownComponent = ({ timeleft, style }) => {
  const getTimeToDisplay = () => {
    if (timeleft == 0) {
      return null;
    } else if (timeleft < 10) {
      return "0" + timeleft;
    } else if (timeleft > 60) {
      return Math.round(timeleft / 60);
    } else {
      return timeleft;
    }
  };

  const shouldBeSeconds = () => {
    if (timeleft > 60) {
      return false;
    }
    return true;
  };

  return (
    <TimerContainer style={style}>
      <Countdown>{getTimeToDisplay()}</Countdown>
      <CircularProgress
        variant="determinate"
        value={100}
        style={{ position: "absolute", color: "white" }}
      />
      <CircularProgress
        variant="determinate"
        value={
          shouldBeSeconds()
            ? timeleft
            : Math.round((100 * (3600 - timeleft)) / 3600)
        }
        style={{ position: "relative", color: "#23c06c" }}
      />
    </TimerContainer>
  );
};

const TimerContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    &.MuiCircularProgress-colorPrimary {
      color: green;
    },
  }
`;
const Countdown = styled.div`
  position: absolute;
  font-size: 16px;
  font-family: ${fonts.bold.name};
  font-weight: ${fonts.bold.weight};
  color: #fff;
  text-shadow: 1px 1px 2px rgba(16, 17, 19, 0.75);
  padding-bottom: 4px;
`;

export default CountdownComponent;
