import { useState, useEffect, useRef, useMemo } from "react";
import useLocale from "../../../../utils/locale/locale.hook";
import colors from "../../../../themes/colors-v2.theme";
import styled from "styled-components";
import {
  createUserVideo,
  deleteUserVideo,
  getUserPresentationVideo,
} from "../../../../services/user-videos.service";
import { uploadFile } from "../../../../utils/s3.utils";
import { PrimaryButton } from "../../../../components/forms/primaryButton.component";
import { Status } from "../../../../utils/status.utils";

interface User {
  id: string;
  pseudo: string;
  profilePicture: ProfilePicture;
  status: Status;
  isFavorite: boolean;
}

interface ProfilePicture {
  id: string;
  path: string;
  description: string | null;
  uploadDate: Date;
  order: number;
  moderationHive: string;
  moderationRating: string;
  moderationAdmin: string;
  adminCheck: boolean;
  moderation: string;
}
export interface UserVideo {
  adminCheck: boolean;
  description: string | null;
  id: number;
  isPorn: boolean;
  isPublic: boolean;
  order: number | null;
  path: string;
  thumbnailPath?: string;
  title: string;
  uid: string;
  uploadDate: Date;
  owner: User;
}

type FirstnameProps = {
  me?: any;
  onChange?: () => void;
};

const maxDuration = 30;

const isVideoTooLong = (file: File, callback: (result: boolean) => void) => {
  const videoUrl = URL.createObjectURL(file);
  const videoElement = document.createElement("video");
  videoElement.src = videoUrl;

  videoElement.addEventListener("loadedmetadata", async () => {
    const videoDuration = videoElement.duration;

    URL.revokeObjectURL(videoUrl);
    callback(videoDuration > maxDuration);
  });
};

const PresentationVideo = (props: FirstnameProps) => {
  const locale = useLocale();
  const me = props.me;
  const [isLoading, setIsLoading] = useState(false);
  const [video, setVideo] = useState<UserVideo | null>(null);
  const fileInputRef = useRef(null);

  const handleUploadVideo = async (file: File) => {
    if (!file) return;

    setIsLoading(true);
    isVideoTooLong(file, async (result) => {
      if (result === true) {
        alert(locale("error.video.too_long"));
        setIsLoading(false);
        return;
      }

      const { path, error } = await uploadFile(file);
      if (error) {
        alert(locale(error));
        setIsLoading(false);
      } else {
        const videoToSend = {
          path: path,
          title: locale("edit_profile.presentation-video"),
          owner: {
            id: props.me.id,
            pseudo: props.me.pseudo,
            profilePicture: props.me.profilePicture,
          },
          isPublic: true,
          isPorn: false,
          isPresentation: true,
        };
        const res = await createUserVideo(videoToSend);
        if (res.error) {
          alert(locale(res.error));
          setIsLoading(false);
          return;
        }
        getVideo();
        setIsLoading(false);
      }
    });
  };

  const handleDeleteVideo = async () => {
    await deleteUserVideo({ userVideoUid: video?.uid });
    setVideo(null);
  };

  async function getVideo() {
    const video = await getUserPresentationVideo(me.id);
    if (!video.error) setVideo(video);
  }

  useEffect(() => {
    getVideo();
  }, []);

  const videoPath = useMemo(() => {
    return video
      ? video.path?.replace(
          "bearwww-uploads-staging.s3.eu-west-3.amazonaws.com",
          "d2tiq3wo24jtl5.cloudfront.net"
        )
      : null;
  }, [video]);

  return (
    <>
      <Sub>{locale("profile_edit.presentation.sub_description")}</Sub>
      {isLoading ? (
        <Box>
          <Title>{locale("profile_edit.presentation.title-loading")}</Title>
          <progress></progress>
        </Box>
      ) : video ? (
        <Box>
          <video controls style={{ width: "100%" }}>
            <source src={videoPath} type="video/mp4" />
          </video>
          <PrimaryButton size="large" onClick={handleDeleteVideo}>
            Delete
          </PrimaryButton>
        </Box>
      ) : (
        <Box>
          <img
            src="/assets/icons/messages/icons-actions-video-white.svg"
            alt="video"
          />
          <Title>{locale("profile_edit.presentation.title")}</Title>
          <Description>
            {locale("profile_edit.presentation.description")}
          </Description>
          <input
            ref={fileInputRef}
            type="file"
            onChange={(e) => {
              const file = e.target?.files;
              if (file.length === 0) {
                return;
              }
              handleUploadVideo(file[0]);
              e.target.value = "";
            }}
            accept="video/mp4,video/x-m4v,video/avi,video/mpeg"
            style={{ display: "none" }}
          />

          <PrimaryButton isActive onClick={() => fileInputRef.current.click()}>
            <div style={{ transform: "scale(1.4)" }}>+</div>
          </PrimaryButton>
        </Box>
      )}
    </>
  );
};
export default PresentationVideo;

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.darkGray};
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${colors.grayBorder};
  padding: 35px;
  box-sizing: border-box;
`;

const Title = styled.div`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
`;

const Description = styled.div`
  color: ${colors.darkText};
  font-size: 10px;
  font-weight: 500;
`;

const Sub = styled.div`
  font-size: 12px;
  color: ${colors.darkText};
`;
