import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import CheckboxSwitch from "../../../components/forms/checkbox-switch.component";
import DeleteAccount from "./account/delete-account.component";
import { Section } from "./settings-styled.component";

import ExternalLink from "../../../components/miscellaneous/external-link.component";
import { getMe, updateUser } from "../../../services/users.service";
import MeSlice from "../../../store/slices/me.slice";
import { getCurrentLanguage } from "../../../utils/locale/hreflang.utils";
import useLocale from "../../../utils/locale/locale.hook";
import OneSignal from "react-onesignal";

const langToTermsOfUse = {
  fr: "https://support.bearwww.com/fr_FR/s%C3%A9curit%C3%A9-vie-priv%C3%A9e/conditions-utilisation",
  en: "https://support.bearwww.com/security-privacy/terms-of-use",
  de: "https://support.bearwww.com/sicherheit-privatsph%C3%A4re/nutzungsbedingungen",
  es: "https://support.bearwww.com/es_ES/nutzungsbedingungen",
  it: "https://support.bearwww.com/it_IT/termos-de-uso",
  pt: "https://support.bearwww.com/pt_PT/gebruiksvoorwaarden",
  ca: "https://support.bearwww.com/es_ES/nutzungsbedingungen",
  nl: "https://support.bearwww.com/nl_NL/termini-di-utilizzo",
};

const langToGuidelines = {
  fr: "https://support.bearwww.com/fr_FR/guides-faqs/standards-de-la-communaut%C3%A9",
  en: "https://support.bearwww.com/en_US/standards-de-la-communaut%C3%A9",
  de: "https://support.bearwww.com/de_DE/standards-de-la-communaut%C3%A9",
  es: "https://support.bearwww.com/es_ES/standards-de-la-communaut%C3%A9",
  it: "https://support.bearwww.com/it_IT/normas-comunitarias",
  pt: "https://support.bearwww.com/pt_PT/norme-comuniarie",
  ca: "https://support.bearwww.com/es_ES/standards-de-la-communaut%C3%A9",
  nl: "https://support.bearwww.com/nl_NL/standards-de-la-communaut%C3%A9",
};

const langToPrivacyPolicy = {
  fr: "https://support.bearwww.com/fr_FR/s%C3%A9curit%C3%A9-vie-priv%C3%A9e/politique-confidentialit%C3%A9",
  en: "https://support.bearwww.com/en_US/politique-confidentialit%C3%A9",
  es: "https://support.bearwww.com/es_ES/privacybeleid",
  it: "https://support.bearwww.com/it_IT/privacy-policy",
  pt: "https://support.bearwww.com/pt_PT/politica-pricacidad",
  ca: "https://support.bearwww.com/es_ES/privacybeleid",
  nl: "https://support.bearwww.com/nl_NL/privacy-policy",
};

const Privacy = () => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const language = getCurrentLanguage();

  const [visibility, setVisibility] = useState<boolean>(false);
  const [ageVisibility, setAgeVisibility] = useState<boolean>(false);
  const [locationOptin, setLocationOptin] = useState<boolean>(false);
  const [videoCallOptin, setVideoCallOptin] = useState<boolean>(false);
  const [isVisibleInDiscoverTab, setIsVisibleInDiscoverTab] =
    useState<boolean>(false);
  const [pushNotificationOptin, setPushNotificationOptin] =
    useState<boolean>(false);
  const [soundNotificationOptin, setSoundNotificationOptin] =
    useState<boolean>(false);
  const [hasAllowedNSFW, setHasAllowedNSFW] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    const getData = async () => {
      const res = await getMe();
      setVisibility(res.visibility === "public");
      setVideoCallOptin(res.videoCallOptin);
      setAgeVisibility(res.ageVisibility === "public");
      setLocationOptin(res.locationOptin);
      setIsVisibleInDiscoverTab(res.isVisibleInDiscoverTab);
      setPushNotificationOptin(res.pushNotificationOptin);
      setSoundNotificationOptin(res.soundNotificationOptin);
      setHasAllowedNSFW(!res.hasBlockedNudes);
      setEmail(res.email);
    };
    getData();
  }, []);

  const onChangeVisibility = async (value: boolean) => {
    const res = await updateUser({ visibility: value ? "public" : "private" });
    if (res?.success) {
      setVisibility(value);
      dispatch(
        MeSlice.actions.updateMe({ visibility: value ? "public" : "private" })
      );
    }
  };

  const onChangeVideoCallOptin = async (value: boolean) => {
    const res = await updateUser({ videoCallOptin: value });
    if (res?.success) {
      setVideoCallOptin(value);
      dispatch(MeSlice.actions.updateMe({ videoCallOptin: value }));
    }
  };

  const onChangeLocationOptin = async (value: boolean) => {
    const res = await updateUser({ locationOptin: value });
    if (res?.success) {
      setLocationOptin(value);
      dispatch(MeSlice.actions.updateMe({ locationOptin: value }));
    }
  };

  const onChangeDiscoverVisibility = async (value: boolean) => {
    const res = await updateUser({ isVisibleInDiscoverTab: value });
    if (res?.success) {
      setIsVisibleInDiscoverTab(value);
      dispatch(MeSlice.actions.updateMe({ isVisibleInDiscoverTab: value }));
    }
  };

  const onChangeAgeVisibility = async (value: boolean) => {
    const res = await updateUser({
      ageVisibility: value ? "public" : "private",
    });
    if (res?.success) {
      setAgeVisibility(value);
      dispatch(
        MeSlice.actions.updateMe({
          ageVisibility: value ? "public" : "private",
        })
      );
    }
  };

  const onChangePushNotification = async (value: boolean) => {
    const res = await updateUser({ pushNotificationOptin: value });
    if (res?.success) {
      setPushNotificationOptin(value);
      dispatch(MeSlice.actions.updateMe({ pushNotificationOptin: value }));
      updateOneSignalSubscription(value);
    }
  };

  const onChangeSoundNotification = async (value: boolean) => {
    const res = await updateUser({ soundNotificationOptin: value });
    if (res?.success) {
      setSoundNotificationOptin(value);
      dispatch(MeSlice.actions.updateMe({ soundNotificationOptin: value }));
    }
  };

  // Disabling NSFW setting until moderation is back
  const onChangeHasAllowedNSFW = async (value: boolean) => {
    const res = await updateUser({ hasBlockedNudes: !value });
    if (res?.success) {
      setHasAllowedNSFW(value);
      dispatch(MeSlice.actions.updateMe({ hasBlockedNudes: !value }));
    }
  };

  // We update at the same time Push and Email Subscription as agreed with Alain
  const updateOneSignalSubscription = async (value: boolean) => {
    try {
      if (value) {
        await OneSignal.User.PushSubscription.optIn();
      } else {
        await OneSignal.User.PushSubscription.optOut();
      }
    } catch (error) {
      console.error(`error updating OneSignal subscriptions: ${error}`);
    }
    
  }

  return (
    <Container>
      <Section>
        <CheckboxSwitch
          value={visibility}
          onChange={onChangeVisibility}
          text={locale("privacy.profile_visibility.title")}
          description={locale("privacy.profile_visibility.description")}
        />
      </Section>
      <Section>
        <CheckboxSwitch
          value={videoCallOptin}
          onChange={onChangeVideoCallOptin}
          text={locale("privacy.video_optin.title")}
          description={locale("privacy.video_optin.description")}
        />
      </Section>
      <Section>
        <CheckboxSwitch
          value={ageVisibility}
          onChange={onChangeAgeVisibility}
          text={locale("privacy.age_visible.title")}
          description={locale("privacy.age_visible.description")}
        />
      </Section>
      <Section>
        <CheckboxSwitch
          value={locationOptin}
          onChange={onChangeLocationOptin}
          text={locale("privacy.location_optin.title")}
          description={locale("privacy.location_optin.description")}
        />
      </Section>
      <Section>
        <CheckboxSwitch
          value={hasAllowedNSFW}
          onChange={onChangeHasAllowedNSFW}
          text={locale("account.settings.accept_nsfw_images")}
        />
      </Section>
      <Section>
        <CheckboxSwitch
          value={isVisibleInDiscoverTab}
          onChange={onChangeDiscoverVisibility}
          text={locale("privacy.discover_optin.title")}
          description={locale("privacy.discover_optin.description")}
        />
      </Section>
      <Section>
        <CheckboxSwitch
          value={pushNotificationOptin}
          onChange={onChangePushNotification}
          text={locale("privacy.push_notification_optin.title")}
          description={locale("privacy.push_notification_optin.description")}
        />
      </Section>
      <Section>
        <CheckboxSwitch
          value={soundNotificationOptin}
          onChange={onChangeSoundNotification}
          text={locale("privacy.sound_notification_optin.title")}
          description={locale("privacy.sound_notification_optin.description")}
        />
      </Section>

      <ExternalLink
        href={langToPrivacyPolicy[language] ?? langToPrivacyPolicy["en"]}
        label={locale("account.settings.privacy_policy")}
      />
      <ExternalLink
        href={langToTermsOfUse[language] ?? langToTermsOfUse["en"]}
        label={locale("account.settings.general_conditions_use")}
      />
      <ExternalLink
        href={langToGuidelines[language] ?? langToGuidelines["en"]}
        label={locale("title_guidelines")}
      />

      <DeleteSection>
        <DeleteAccount />
      </DeleteSection>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  max-width: 798px;
  margin: auto;
  width: 100% !important;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DeleteSection = styled.div`
  padding: 0 16px;
  box-sizing: border-box;
  margin-bottom: 50px;
`;

export default Privacy;
