import styled from "styled-components/macro";

import useLocale from "../../utils/locale/locale.hook";

import colors from "../../themes/colors-v2.theme";
import fonts from "../../themes/fonts.theme";
import Text from "../../components/style/text.component";
import PlayStoreButton from "../../components/miscellaneous/play-store-button.component";
import AppStoreButton from "../../components/miscellaneous/app-store-button.component";

interface Props {
  showGetTheApp: boolean;
}
const GetTheAppHeader = (props: Props) => {
  const { showGetTheApp } = props;
  const locale = useLocale();

  return (
    <Container showGetTheApp={showGetTheApp}>
      <Text
        dangerouslySetInnerHTML={{
          __html: locale("global.prefer_to_use_the_app"),
        }}
      />
      <ButtonsContainer>
        {navigator.userAgent.includes("Android") ? (
          <PlayStoreButton style={{ width: 138, height: 38 }} />
        ) : (
          <AppStoreButton style={{ width: 138, height: 38 }} />
        )}
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.nav<{ showGetTheApp: boolean }>`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ showGetTheApp }) =>
    showGetTheApp ? colors.white : "transparent"};
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: center;
  border-top: 1px solid ${colors.lightGrey8};
  border-bottom: 1px solid ${colors.lightGrey8};
  opacity: ${({ showGetTheApp }) => (showGetTheApp ? 1 : 0)};

  @media (max-width: 1000px) {
    padding: ${({ showGetTheApp }) => (showGetTheApp ? "19px 25px" : 0)};
    ${({ showGetTheApp }) => !showGetTheApp && "height: 0;"}
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 16.5px;
`;

const Button = styled.button`
  padding: 10px 16px;
  border-radius: 8px;
  background-color: ${colors.darkOrange1};
  border: 1px solid ${colors.darkOrange3};
  font-size: 14px;
  box-sizing: border-box;
  color: ${colors.white};
  font-weight: ${fonts.semiBold.weight};
  cursor: pointer;
`;

export default GetTheAppHeader;
