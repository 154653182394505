import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

let cachedHistoryStack = [location.pathname];

export const useRouterNavigationTracker = () => {
  const history = useHistory();

  const [historyStack, setHistoryStack] = useState(cachedHistoryStack);
  useEffect(() => {
    const unlisten = history.listen((location) => {
      const pageTitle = getPageTitle(location.pathname);
      document.title = pageTitle;
      const pagePath = location.pathname;

      if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('event', 'page_view', {
          page_title: pageTitle,
          page_path: pagePath,
        });
        console.log(`Tracked page view: ${pageTitle} (${pagePath})`);
      } else {
        console.warn('gtag not defined');
      }
    });

    const getPageTitle = (path: string): string => {
      if (path.startsWith('/messages')) {
        return 'Messages';
      }
      switch (path) {
        case '/nearby':
          return 'Nearby';
        case '/discover':
          return 'Discover'
        case '/explore':
          return 'Explore';
        case '/trips':
          return 'Trips';
        case '/viewers':
            return 'Viewers';
        case '/favorites':
          return 'Favorites';
        case '/classification':
          return 'Classification';
        case '/shared-albums':
          return 'Shared Albums';
        case '/memberships':
          return 'Memberships';
        default:
          return 'Other Pages';
      }
    };

    // Cleanup listener
    return () => unlisten();
  }, [history]);
  useEffect(() => {
    const unlisten = history.listen((location) => {
      cachedHistoryStack = [location.pathname, ...cachedHistoryStack];
      setHistoryStack(cachedHistoryStack);
    });

    return () => unlisten();
  }, []);

  return historyStack;
};
